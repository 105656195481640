import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import Vue3Toastify from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import "./assets/css/custom-toast.css";

import axiosInstance from "./axios";

const app = createApp(App);

app.use(router);
app.use(Vue3Toastify, {
  autoClose: 3000,
  style: {
    opacity: "1",
    userSelect: "initial",
    className: "my-toast",
  },
});

app.config.globalProperties.$axios = axiosInstance;

app.mount("#app");
