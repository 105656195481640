<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    class="icon"
  >
    <path d="M11 13H5V11H11V5H13V11H19V13H13V19H11V13Z" :fill="fill" />
  </svg>
</template>

<script>
export default {
  props: {
    fill: {
      type: String,
      default: "currentColor", // Use currentColor to inherit from CSS
    },
    width: {
      type: String,
      default: "24",
    },
    height: {
      type: String,
      default: "24",
    },
  },
};
</script>

<style scoped>
/* Scoped styles specific to the icon */
.icon {
  transition: fill 0.3s ease;
}
</style>
