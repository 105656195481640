<template>
  <NavBar/>
  <div class="articles-container">

    <article class="article second-page" id="market">
        <div class="footer-container">
          <img class="footer-img" src="../assets/img/background/bg_modelo_negocio_06.jpg" />
        </div>
        <div class="content">
          <div class="">Generamos 26 tickets para que participes como inversionista y recibas los beneﬁcios de construir negocios éticos entre personas conﬁables</div>
          <div class="title-container">
            <div class="title medium">VALOR TICKET</div>
            <div class="ticket-container">
              <img style="max-width: 100%; max-height: 100%" src="../assets/img/components/ticket.svg" />
              <p class="ticket-price">8.000 USD</p>
            </div>

          </div>
          <div class="info-container">
            <div class="set-info-container">
              <p class="m-0 label">Cada ticket genera en beneficio:</p>
              <div class="lower-info-container">
                <div class="data-container">
                  <div class="icon-checkmark"><img style="max-width: 100%; max-height: 100%" src="../assets/img/icon/checkmark_are.svg" /></div>
                  <p class="check-text"><span class="b">17%</span> de retorno mensual por 17 meses</p>
                </div>
                <div class="data-container">
                  <div class="icon-checkmark"><img style="max-width: 100%; max-height: 100%" src="../assets/img/icon/checkmark_are.svg" /></div>
                  <p class="check-text"><span class="b">100%</span> del capital en el mes 17</p>
                </div>
              </div>
            </div>
            <div class="set-info-container">
              <p class="m-0 label b">Beneficio adicional por Pronto Pago*:</p>
              <div class="lower-info-container">
                
            </div>
            <div class="data-container">
                  <div class="icon-checkmark"><img style="max-width: 100%; max-height: 100%" src="../assets/img/icon/checkmark_are.svg" /></div>
                  <p class="check-text">Derecho a renovación de contrato en los mismos términos</p>
                </div>
              </div>
            <div class="set-info-container">
              <p class="m-0 label">Para compras desde <span class="b">8 tickets</span> en adelante, recibe un 10% adicional en retorno cada mes</p>
            </div>
          </div>
          <div class="whole-medium-section-container">
            <div class="medium-section-container">
              <p class="green-title">TIENES</p>
              <div class="counters-container-small">
                <div class="hexagon-label-container-small second-hexagon">
                  <img style="max-width: 100%; max-height: 100%" src="../assets/img/components/contenedor_hexagonos.svg" />          
                  <div class="counter-wrap-small">
                    <p class="counter-small">{{ personalHours }}</p>
                  </div>
                  <p class="counter-label-small">HORAS</p>
                </div>
                <div class="hexagon-label-container-small third-hexagon">
                  <img style="max-width: 100%; max-height: 100%" src="../assets/img/components/contenedor_hexagonos.svg" />
                  <div class="counter-wrap-small">
                    <p class="counter-small">{{ personalMinutes }}</p>
                  </div>
                  <p class="counter-label-small">MINUTOS</p>
                </div>
                <div class="hexagon-label-container-small fourth-hexagon">
                  <img style="max-width: 100%; max-height: 100%" src="../assets/img/components/contenedor_hexagonos.svg" />
                  <div class="counter-wrap-small">
                    <p class="counter-small">{{ personalSeconds }}</p>
                  </div>
                  <p class="counter-label-small">SEGUNDOS</p>
                </div>
              </div>
              <p class="green-title">PARA OBTENER BENEFICIO DE PRONTO PAGO</p>
            </div>
            <div class="content-content">
              <div class="upper-section-container">
                <div class="title-section-container">
                  <div class="horizontal-line"></div>
                  <div class="title-container">
                    <p class="title">TICKETS</p>
                    <p class="title-green">DISPONIBLES</p>
                  </div>
                  <div class="horizontal-line"></div>
                </div>
              </div>
              <div class="honeycomb-container">
                <img style="max-width: 100%; max-height: 100%" src="../assets/img/components/honeycomb.svg" />
                <div class="__1 unactive-tape" :class="{'active-tape-container': variables[0]}">
                    <img style="max-width: 100%; max-height: 100%;"   src="../assets/img/icon/ticket-active.svg" />
                  </div>
                  <div class="__2 unactive-tape" :class="{'active-tape-container': variables[1]}">
                    <img style="max-width: 100%; max-height: 100%" src="../assets/img/icon/ticket-active.svg" />
                  </div>
                  <div class="__3 unactive-tape" :class="{'active-tape-container': variables[2]}">
                    <img style="max-width: 100%; max-height: 100%" src="../assets/img/icon/ticket-active.svg" />
                  </div>
                  <div class="__4 unactive-tape" :class="{'active-tape-container': variables[3]}">
                    <img style="max-width: 100%; max-height: 100%" src="../assets/img/icon/ticket-active.svg" />
                  </div>
                  <div class="__5 unactive-tape" :class="{'active-tape-container': variables[4]}">

                    <img style="max-width: 100%; max-height: 100%" src="../assets/img/icon/ticket-active.svg" />
                  </div>
                  <div class="__6 unactive-tape" :class="{'active-tape-container': variables[5]}">

                    <img style="max-width: 100%; max-height: 100%" src="../assets/img/icon/ticket-active.svg" />
                  </div>
                  <div class="__7 unactive-tape" :class="{'active-tape-container': variables[6]}">

                    <img style="max-width: 100%; max-height: 100%" src="../assets/img/icon/ticket-active.svg" />
                  </div>
                  <div class="__8 unactive-tape" :class="{'active-tape-container': variables[7]}">
                    <img style="max-width: 100%; max-height: 100%" src="../assets/img/icon/ticket-active.svg" />
                  </div>
                  <div class="__9 unactive-tape" :class="{'active-tape-container': variables[8]}">

                    <img style="max-width: 100%; max-height: 100%" src="../assets/img/icon/ticket-active.svg" />
                  </div>
                  <div class="__10 unactive-tape" :class="{'active-tape-container': variables[9]}">

                    <img style="max-width: 100%; max-height: 100%" src="../assets/img/icon/ticket-active.svg" />
                  </div>
                  <div class="__11 unactive-tape" :class="{'active-tape-container': variables[10]}">

                    <img style="max-width: 100%; max-height: 100%" src="../assets/img/icon/ticket-active.svg" />
                  </div>
                  <div class="__12 unactive-tape" :class="{'active-tape-container': variables[11]}">

                    <img style="max-width: 100%; max-height: 100%" src="../assets/img/icon/ticket-active.svg" />
                  </div>
                  <div class="__13 unactive-tape" :class="{'active-tape-container': variables[12]}">

                    <img style="max-width: 100%; max-height: 100%" src="../assets/img/icon/ticket-active.svg" />
                  </div>
                  <div class="__14 unactive-tape" :class="{'active-tape-container': variables[13]}">

                    <img style="max-width: 100%; max-height: 100%" src="../assets/img/icon/ticket-active.svg" />
                  </div>
                  <div class="__15 unactive-tape" :class="{'active-tape-container': variables[14]}">

                    <img style="max-width: 100%; max-height: 100%" src="../assets/img/icon/ticket-active.svg" />
                  </div>
                  <div class="__16 unactive-tape" :class="{'active-tape-container': variables[15]}">

                    <img style="max-width: 100%; max-height: 100%" src="../assets/img/icon/ticket-active.svg" />
                  </div>
                  <div class="__17 unactive-tape" :class="{'active-tape-container': variables[16]}">

                    <img style="max-width: 100%; max-height: 100%" src="../assets/img/icon/ticket-active.svg" />
                  </div>
                  <div class="__18 unactive-tape" :class="{'active-tape-container': variables[17]}">

                    <img style="max-width: 100%; max-height: 100%" src="../assets/img/icon/ticket-active.svg" />
                  </div>
                  <div class="__19 unactive-tape" :class="{'active-tape-container': variables[18]}">

                    <img style="max-width: 100%; max-height: 100%" src="../assets/img/icon/ticket-active.svg" />
                  </div>
                  <div class="__20 unactive-tape" :class="{'active-tape-container': variables[19]}">

                    <img style="max-width: 100%; max-height: 100%" src="../assets/img/icon/ticket-active.svg" />
                  </div>
                  <div class="__21 unactive-tape" :class="{'active-tape-container': variables[20]}">

                    <img style="max-width: 100%; max-height: 100%" src="../assets/img/icon/ticket-active.svg" />
                  </div>
                  <div class="__22 unactive-tape" :class="{'active-tape-container': variables[21]}">

                    <img style="max-width: 100%; max-height: 100%" src="../assets/img/icon/ticket-active.svg" />
                  </div>
                  <div class="__23 unactive-tape" :class="{'active-tape-container': variables[22]}">

                    <img style="max-width: 100%; max-height: 100%" src="../assets/img/icon/ticket-active.svg" />
                  </div>
                  <div class="__24 unactive-tape" :class="{'active-tape-container': variables[23]}">
                  
                    <img style="max-width: 100%; max-height: 100%" src="../assets/img/icon/ticket-active.svg" />
                  </div>
                  <div class="__25 unactive-tape" :class="{'active-tape-container': variables[24]}">

                    <img style="max-width: 100%; max-height: 100%" src="../assets/img/icon/ticket-active.svg" />
                  </div>
                  <div class="__26 unactive-tape" :class="{'active-tape-container': variables[25]}">
                    <img style="max-width: 100%; max-height: 100%" src="../assets/img/icon/ticket-active.svg" />
                  </div>
                <span class="ticket-number _1">1</span>
                <span class="ticket-number _2">2</span>
                <span class="ticket-number _3">3</span>
                <span class="ticket-number _4">4</span>
                <span class="ticket-number _5">5</span>
                <span class="ticket-number _6">6</span>
                <span class="ticket-number _7">7</span>
                <span class="ticket-number _8">8</span>
                <span class="ticket-number _9">9</span>
                <span class="ticket-number _10">10</span>
                <span class="ticket-number _11">11</span>
                <span class="ticket-number _12">12</span>
                <span class="ticket-number _13">13</span>
                <span class="ticket-number _14">14</span>
                <span class="ticket-number _15">15</span>
                <span class="ticket-number _16">16</span>
                <span class="ticket-number _17">17</span>
                <span class="ticket-number _18">18</span>
                <span class="ticket-number _19">19</span>
                <span class="ticket-number _20">20</span>
                <span class="ticket-number _21">21</span>
                <span class="ticket-number _22">22</span>
                <span class="ticket-number _23">23</span>
                <span class="ticket-number _24">24</span>
                <span class="ticket-number _25">25</span>
                <span class="ticket-number _26">26</span>

              </div>
              <div class="btn-container">
                <button class="btn">COMPRAR TICKET</button>
                <a href="https://web.whatsapp.com/send?phone=573197403514&text=Hello%20I%20need%20support" target="_blank">contact</a>
              </div>
            </div>
          </div>
          <div class="bottom-box-container">
            <p class="bottom-text">En nuestro contrato, eliges si deseas recibir los beneficios en USD o en Esmeraldas Certificadas de precio equivalente</p>
            <div class="horizontal-line"></div>
            <p class="bottom-text">Los retornos inician 90 días después de realizado el pago, con el retroactivo de los meses anteriores</p>
          </div>
        </div>
    </article>

  </div>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
import { toast } from "vue3-toastify"

// @ is an alias to /src
//import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'PersonalView',
  data() {
    return {
      endOfTicketDate: "",
      personalHours: 0,
      personalMinutes: 0,
      personalSeconds: 0,
      personalCountDownDate: 0,
      variables: [
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
      ],
    }
  },
  components: {
    NavBar
  },
  methods: {
    async setTapes() {
      await this.getRemainingTickets()
      console.log(this.ticketsRemaining)
      for (let i = 0; i < this.ticketsRemaining; i++) {
        this.variables[i] = false;
      }
      return;
    },
    async setPersonalInformation() {
      await this.$axios.get(`/tokens/${this.$route.query.token}`)
        .then((res) => {
          if (res.status === 200 || res.status === 201) {
            this.username = res.data.username.toUpperCase();  
            this.personalCountDownDate = parseInt(res.data.date);
            return;
          }
        })
        .catch((err) => {
          switch (err.response.status) {
            case 400:
              console.log(400);
              this.$router.push({ name: "error" });
              break;

            case 403:
              this.$router.push({ name: "error" });
              break;

            default:
              this.$router.push({ name: "error" });
              break;
          }
          console.log(err.response.status);
        });
    },
    async getEndOfTicketDate() {
      const countDownDate = new Date("Sep 20, 2024 00:00:00").getTime();

      const updateCountdown = () => {
        // Get today's date and time
        const now = new Date().getTime();

        const personalDistance = this.personalCountDownDate - now;


        const personalHours = Math.floor(personalDistance / (1000 * 60 * 60));
        this.personalMinutes = Math.floor((personalDistance % (1000 * 60 * 60)) / (1000 * 60));
        this.personalSeconds = Math.floor((personalDistance % (1000 * 60)) / 1000);
        if (personalHours < 0) {
        } else {
          this.personalHours = personalHours;
        }
        

        // If the count down is finished, write some text
        if (personalDistance < 0) {
          this.personalHours = 0
          this.personalMinutes = 0
          this.personalSeconds = 0
          clearInterval(this.interval);
        }
      };

      this.interval = setInterval(updateCountdown, 1000);
    },
      async getRemainingTickets() {
        try {
        const response = await this.$axios.get("/tickets");
        console.log(response.data.ticketsRemaining)
        this.ticketsRemaining = response.data.ticketsRemaining;
        const iterate = 26 - parseInt(this.ticketsRemaining);
        for (let i = 0; i < iterate; i++) {
          this.variables[i] = true;
        }
        return;
      } catch (error) {
        console.log(error.response.status)
        if (error.response.status == 500 || error.response.status == 501) {
          console.log(error)
          this.notify("Un error ocurrió, por favor refresca la página")
          return;
        }
        return;
      }
    },
    notify(message) {
      toast(message, {
        position: toast.POSITION.TOP_RIGHT,
        transition: toast.TRANSITIONS.FLIP,
        toastClassName: "my-toast-users"
      })
    }
  },
  async created() {
    await this.setPersonalInformation();
    
    await this.getEndOfTicketDate();
    await this.getRemainingTickets();

    
  },
  beforeDestroy() {
    clearInterval(this.interval);
  }
}
</script>

<style scoped>

.articles-container {
  height: 100%;
  margin: 0;
  scroll-behavior: smooth;
  display: flex;
  flex-direction: column;
  gap: 0;
  padding: 0;
  align-content: center;
  width: 100%;
  text-align: center;
  overflow-x: hidden;
  position: relative;
}

.article {
  height: 715px;
  display: flex;
  margin: 0px auto;
  color: #172d1b;
  overflow: hidden;
  position: relative;
}

@font-face {
  font-family: Brilliant-Cut;
  src: url(../assets/fonts/Brilliant\ Cut\ Pro\ Medium.ttf);
}

.logo-container {
  display: flex;
  flex-direction: column;
  justify-content: start;
  height: 52px;
  align-items: end;
}

.counter-hexagon-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.hexagon-label-container {
  display: flex;
  flex-direction: column;
  width: 76px;
}

.counter-label {
  transform: translateY(-75px);
  font-size: 11px;
  text-align: center;
}

.logo-single {
  height: 34px;
  width: 34px;
}

.title-container {
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 9px;
  margin: 0 16px;
}



.title-section-container .title-container .title {
  font-size: 16px;
}

.title-section-container .title-container {
  gap: 0;
}

.title-section-container .title-container .title-green {
  font-size: 16px;
  color: #08593a;
}


.footer-container {
  height: 284.8px;
  width: 700px;
  box-sizing: border-box;
  z-index: 1;
  position: absolute;
  transform: translateY(418px);
  overflow-x: visible;
}

.footer-img {
  max-height: 100%;
  width: 100%;
}

.second-page .footer-container {
  transform: translateY(905px);
}

.second-page   {
  overflow: hidden;
  display: flex;
  flex-direction: column-reverse;
  justify-content: start;
  align-items: center;
  margin: 55px auto 0 auto;
  padding: 0;
  height: 1185px;
  width: 100%;
}

.second-page .content {
  display: flex;
  flex-direction: column;
  justify-content: top;
  align-items: center;
  gap: 27px;
  font-family: "Libre Baskerville", serif;
  z-index: 4;

}

.second-page .content .title-container {
  gap: 0;
}

.whole-medium-section-container {
  display: flex;
  flex-direction: column;
  gap: 55px;
}

.ticket-container {
  width: 265px;
  height: 78.23px;
  margin-top: 4px;
}

.ticket-price {
  font-weight: 700;
  font-size: 25px;
  top: 35px;
  transform: translateX(60px);
  position: absolute;
}

.bottom-box-container {
  display: flex;
  flex-direction: column;
  gap: 6px;
  font-size: 13px;
  background-color: white;
  padding: 16px 36px;
  border: 1px solid #08593a;
  margin: 0 24px;
}

.bottom-box-container * {
  margin: 0;
  width: 100%;
}

.btn-container {
  height: 55px; 
  width: 100%; 
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.btn {
  font-size: 19px;
  border: none;
  padding: 12px 40px;
  max-width: 340px;
  color: white;
  background-color: #08593a;
  font-family: "Libre Baskerville", serif;
  font-style: normal;
  transition: 100ms;
  margin: auto;
}

.btn:hover {
  background-color: #063b27;
}

.data-container {
  display: flex;
  flex-direction: row;
  justify-content: start;
  gap: 8px;
  margin-left: 20px;
  margin: 0 auto;
}


.counter-wrap {
  text-align: center;
  transform: translateY(-93px);
}

.counter {
  font-size: 32px;
  font-weight: 400;
  font-family: Brilliant-Cut;
  margin: 0;
  transform: translateY(33px);
}

.counter-wrap-small {
  text-align: center;
  transform: translateY(-83px);
}

.counter-small {
  font-size: 28px;
  font-weight: 400;
  font-family: Brilliant-Cut;
}

.counter-label {
  transform: translateY(-35px);
  font-size: 10px;
  text-align: center;
  font-family: Brilliant-Cut;
  margin: 0;
}

.counter-label-small {
  transform: translateY(-96px);
  font-size: 10px;
  text-align: center;
  font-family: Brilliant-Cut;
}

.data-container .check-text .text {
  display: inline;
  max-width: 200px;
}



.counters-container {
  display: flex;
  flex-direction: row;
  width: 360px;
  height: 100px;
  justify-content: space-between;
  margin-top: 4px;
}

.medium-section-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.medium-section-container .green-title {
  margin: 0;
  max-width: 290px;
  font-size: 18px;
  font-family: Brilliant-Cut;
  color: #08593a;
}

.counters-container-small {
  display: flex;
  flex-direction: row;
  width: 240px;
  height: 95px;
  justify-content: space-between;
}
.hexagon-label-container {
  display: flex;
  flex-direction: column;
  width: 84px;
}

.hexagon-label-container-small {
  display: flex;
  flex-direction: column;
  width: 74px;
}

.title {
  color: #172d1b;
  font-family: "Libre Baskerville", serif;
  display: inline;
  font-weight: 400;
  font-style: normal;
}

.img-container {
  display: flex;
  height: 30px;
  align-items: end;
}

.info-container {
  display: flex;
  flex-direction: column;
  gap: 18px;
  width: 530px;
}

.set-info-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  font-size: 13px;
}

.label {
  text-align: start;
  text-align: justify;
  text-justify: inter-word;
}

.lower-info-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.data-container {
  display: flex;
  flex-direction: row;
  justify-content: start;
  gap: 0px;
  margin: 0;
}

.data-container .icon-checkmark {
  width: 14px;
  height: 14px;
  padding: 2px 8px 2px 0;
}

.data-container .check-text {
  text-align: start;
  margin-top: 0;
  margin-bottom: 0;
}

.content-content {
  display: flex;
  flex-direction: column;
  gap: 19px;
  width: 530px;
  align-items: center;
}

.content-content * {
  margin: 0;
}

.upper-section-container {
  display: flex;
  flex-direction: column;
  gap: 13px;
  justify-content: space-between;
  width: 100%;
  font-size: 13px;
}

.title-section-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 16px;
}

.upper-section-container .text {
  text-align: start;
  padding: 0 8px;
}

.horizontal-line {
  width: 100%;
  height: 1px;
  border-top: 1px solid #08593a;
  margin: auto 0;
}

.honeycomb-container {
  width: 530px;
  height: 166.9px;
}

.ticket-number {
  font-family: Brilliant-Cut;
  font-size: 18px;
  color: #08593a;
  text-decoration: none;
}



.unactive-tape {
  position: absolute;
  display: none;
}


.active-tape-container {
  height: 62px;
  width: 62px;
  position: absolute;
  display: block;
  z-index: 2;
}

.__1 {
  translate: 5px -169.1px;

}

.__2 {
  translate: 62.3px -169.1px;
}

.__3 {
  translate: 119.4px -169.1px;
}

.__4 {
  translate: 176.6px -169.1px;
}

.__5 {
  translate: 234px -169.1px;
}

.__6 {
  translate: 291.3px -169.1px;
}

.__7 {
  translate: 348.5px -169.1px;
}

.__8 {
  translate: 405.7px -169.1px;
}

.__9 {
  translate: 462.8px -169.1px;
}

.__10 {
  translate: 33.6px -118.5px;
}

.__11 {
  translate: 91px -118.5px;
}

.__12 {
  translate: 148.2px -118.5px;
}

.__13 {
  translate: 205.4px -118.5px;
}

.__14 {
  translate: 262.8px -118.5px;
}

.__15 {
  translate: 320px -118.5px;
}

.__16 {
  translate: 376.8px -118.5px;
}

.__17 {
  translate: 434.1px -118.5px;
}

.__18 {
  translate: 5px -68.3px;
}

.__19 {
  translate: 62.3px -68.3px;
}

.__20 {
  translate: 119.4px -68.3px;
}

.__21 {
  translate: 176.6px -68.3px;
}

.__22 {
  translate: 234px -68.3px;
}

.__23 {
  translate: 291.3px -68.3px;
}

.__24 {
  translate: 348.5px -68.3px;
}

.__25 {
  translate: 405.7px -68.3px;
}

.__26 {
  translate: 462.8px -68.3px;
}

._1 {
  position: absolute;
  translate: -491px 22.5px;
}

._2 {
  position: absolute;
  translate: -436px 22.5px;
}
._3 {
  position: absolute;
  translate: -379px 22.5px;
  
}
._4 {
  position: absolute;
  translate: -322px 22.5px;
  
}
._5 {
  position: absolute;
  translate: -264px 22.5px;
}
._6 {
  position: absolute;
  translate: -207px 22.5px;
  
}
._7 {
  position: absolute;
  translate: -150px 22.5px;
}
._8 {
  position: absolute;
  translate: -92px 22.5px;
  
}
._9 {
  position: absolute;
  translate: -35px 22.5px;
  
}
._10 {
  position: absolute;
  translate: -468px 73px;
  
}
._11 {
  position: absolute;
  translate: -409px 73px;
  
}
._12 {
  position: absolute;
  translate: -354px 73px;
  
}
._13 {
  position: absolute;
  translate: -297px 73px;
  
}
._14 {
  position: absolute;
  translate: -239.2px 73px;
  
}
._15 {
  position: absolute;
  translate: -182.2px 73px;
  
}
._16 {
  position: absolute;
  translate: -125.1px 73px;
  
}
._17 {
  position: absolute;
  translate: -67.4px 73px;
}
._18 {
  position: absolute;
  translate: -497.2px 123px;
}
._19 {
  position: absolute;
  translate: -439.3px 123px;
}
._20 {
  position: absolute;
  translate: -385px 123px;
}
._21{
  position: absolute;
  translate: -326px 123px;
  
}
._22 {
  position: absolute;
  translate: -270.9px 123px;
  
}
._23 {
  position: absolute;
  translate: -213.8px 123px;
  
}
._24 {
  position: absolute;
  translate: -157.2px 123px;
  
}
._25 {
  position: absolute;
  translate: -99.5px 123px;
  
}
._26 {
  position: absolute;
  translate: -42.5px 123px;
  
}

.smallest {
  font-size: 16px;
}

.small {
  font-size: 20px;
}

.medium {
  font-size: 24px;
}

.big {
  font-size: 38px;
  line-height: 34px;
}

.gap-0 {
  gap: 0;
}

.b {
  font-weight: 700;
}

.ml-2 {
  margin-left: 20px;
}

.m-0 {
  margin: 0;
}


@media only screen and (max-width: 640px) {
  .content-content {
    width: 344px;
  }

  .first-page {
    height: 750px;
  }

  .second-page {
    height: 1215px;
  }

  .first-page .content {
    gap: 55px;
  }

  .second-page .content {
    gap: 27px;
  }

  .second-page .footer-container {
    transform: translateY(1049px);
  }

  .footer-container {
    width: 410px;
    height: 167px;
    transform: translateY(582px);
    overflow: hidden;
  }

  .info-container {
    width: 344px;
    margin-top: 0px;
  }

  .set-info-container {
    font-size: 15px;
  }

  .upper-section-container {
    font-size: 15px;
  }

  .data-container {
    justify-content: start;
  }

  .lower-info-container {
    flex-direction: column;
  }

  .honeycomb-container {
    width: 344px;
    height: 111.5px;
  }

  .ticket-number {
    font-size: 15px;
  }

  .active-tape-container {
    height: 40.4px;
    width: 40.4px;
    position: absolute;
  }

  .__1 {
    translate: -1px -114px;
  }

  .__2 {
    translate: 37.1px -114px;
  }

  .__3 {
    translate: 75.5px -114px;
  }

  .__4 {
    translate: 113.5px -114px;
  }

  .__5 {
    translate: 152px -114px;
  }

  .__6 {
    translate: 190px -114px;
  }

  .__7 {
    translate: 228.4px -114px;
  }

  .__8 {
    translate: 266.5px -114px;
  }

  .__9 {
    translate: 304.8px -114px;
  }

  .__10 {
    translate: 17.8px -80px;
  }

  .__11 {
    translate: 56px -80px;

  }

  .__12 {
    translate: 94.4px -80px;
  }

  .__13 {
    translate: 132.9px -80px;
  }

  .__14 {
    translate: 170.9px -80px;
  }

  .__15 {
    translate: 209.2px -80px;
  }

  .__16 {
    translate: 247.6px -80px;
  }

  .__17 {
    translate: 285.7px -80px;
  }

  .__18 {
    translate: -1px -46.5px;
  }

  .__19 {
    translate: 37.1px -46.5px;

  }

  .__20 {
    translate: 75.5px -46.5px;

  }

  .__21 {
    translate: 113.5px -46.5px;

  }

  .__22 {
    translate: 152px -46.5px;

  }

  .__23 {
    translate: 190px -46.5px;

  }

  .__24 {
    translate: 228.4px -46.5px;

  }

  .__25 {
    translate: 266.5px -46.5px;

  }

  .__26 {
    translate: 304.8px -46.5px;
  }

  ._1 {
    translate: -328px 13.5px;
  }

  ._2 {
    translate: -292px 13.5px;
  }

  ._3 {
    translate: -254px 13.5px;
  }

  ._4 {
    translate: -216px 13.5px;
  }

  ._5 {
    translate: -177px 13.5px;
  }

  ._6 {
    translate: -139px 13.5px;
  }

  ._7 {
    translate: -101px 13.5px;
  }

  ._8 {
    translate: -63px 13.5px;
  }

  ._9 {
    translate: -25px 13.5px;
  }

  ._10 {
    translate: -315px 47px;
  }

  ._11 {
    translate: -274px 47px;
  }

  ._12 {
    translate: -238px 47px;
  }

  ._13 {
    translate: -200px 47px;
  }

  ._14 {
    translate: -162px 47px;
  }

  ._15 {
    translate: -123px 47px;
  }

  ._16 {
    translate: -85px 47px;
  }

  ._17 {
    translate: -47px 47px;
  }

  ._18 {
    translate: -334px 80px;
  }

  ._19 {
    translate: -295px 80px;
  }

  ._20 {
    translate: -259px 80px;
  }

  ._21 {
    translate: -219px 80px;
  }

  ._22 {
    translate: -183px 80px;
  }

  ._23 {
    translate: -145px 80px;
  }

  ._24 {
    translate: -107px 80px;
  }

  ._25 {
    translate: -68px 80px;
  }

  ._26 {
    translate: -30px 80px;
  }

  .bottom-box-container {
  display: flex;
  flex-direction: column;
  gap: 6px;
  font-size: 13px;
  background-color: white;
  padding: 16px 36px;
  border: 1px solid #08593a;
  margin: -12px 24px 0 24px;
}

  .counters-container {
    width: 338px;
    margin-top: 21px;
  }

  .hexagon-label-container {
    width: 78px;
  }
}

@media only screen and (max-width: 540px) {

  .medium {
  font-size: 20px;
  }

  .big {
    font-size: 35px;
  }
  .ticket-price {
    font-weight: 700;
    font-size: 25px;
    top: 30px;
  }
}
</style>