<template>
  <router-view/>
</template>

<script>

export default {
  name: "App",
}

</script>


<style>

:root {
  color-scheme: light !important;
  background-color: #ffffff !important;
  color: #000000 !important;
}

#app {
  margin: 0;
  width: 100%;
  scroll-behavior: smooth;
  background: #fff;
  color: #000;
}

router-link,
a {
  -webkit-tap-highlight-color: transparent;
  outline: none;
  -webkit-focus-ring-color: transparent;
}

router-link:focus,
a:focus {
  outline: none;
}
</style>
