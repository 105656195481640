<template>
    <div :class="{hidden: isHidden}" class="nav-bar">
      <img src="../assets/img/icon/logo_ARE_header.png" class="nav-logo" @click="onImgClick()"/>
    <nav class="nav-items">
      <!-- <div class="nav-box-item"><router-link @click='onGoTo("about-us")' :class='{active: this.isAboutUs}' :to="`/about-us?token=${this.$route.query.token}`" class="nav-item">NOSOTROS</router-link></div>
      <div v-if="tooltipVisible" class="tooltip on">Próximamente</div>
      <div class="nav-box-item"><span @click="showTooltip()"  class="nav-item disabled">COMUNIDAD</span></div> -->
      <!-- <div class="nav-box-item"><router-link @click='onGoTo("artists")' :class='{active: this.isArtists}' :to="`/artists?token=${this.$route.query.token}`" class="nav-item" href="#approach">ARTISTAS</router-link></div> -->
      <!-- <div class="nav-box-item"><router-link @click='onGoTo("tickets")' :class='{active: this.isTickets}' :to="`/?token=${this.$route.query.token}`" class="nav-item">TICKETS</router-link></div> -->
    </nav>
    <!-- <button class="hamburger-btn"><img src="../assets/img/icon/menu.svg" /></button> -->
    <!-- <label class="hamburger-menu">
      <input type="checkbox" ref="menuCheckbox" @click="toggleMenu">
    </label> -->
    </div>
  <!-- <aside class="sidebar" :class="{open: isMenuOpen}" ref="sidebar"> -->
    <!-- <router-link @click='onGoTo("about-us")' :class='{active: this.isAboutUs}' :to="`/about-us?token=${this.$route.query.token}`" class="nav-item z-10" @click.native="closeMenu">NOSOTROS</router-link>
    <div v-if="tooltipVisible" class="tooltip on">Próximamente</div>
    <span @click="showTooltip()" class="nav-item z-11 disabled">COMUNIDAD</span> -->
    <!-- <router-link @click='onGoTo("artists")' :class='{active: this.isArtists}' :to="`/artists?token=${this.$route.query.token}`" class="nav-item z-10" @click.native="closeMenu">ARTISTAS</router-link> -->
    <!-- <router-link @click='onGoTo("tickets")' :class='{active: this.isTickets}' :to="`/?token=${this.$route.query.token}`" class="nav-item z-10" @click.native="closeMenu">TICKETS</router-link> -->
  <!-- </aside> -->
</template>

<script>

export default {
  name: "NavBar",
  props: {
    isHidden: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      isScrolled: false,
      isMenuOpen: false,
      isAboutUs: false,
      isArtists: false,
      isCommunity: false,
      isTickets: false,
      tooltipVisible: false
    };
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
    document.addEventListener('click', this.handleClickOutside);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
    document.removeEventListener('click', this.handleClickOutside);
  },
  
  methods: {
    handleScroll() {
      this.isScrolled = window.scrollY > 0;
    },
    toggleMenu() {
        this.isMenuOpen = !this.isMenuOpen
        console.log(this.isMenuOpen)
    },
    handleClickOutside(event) {
      if (this.isMenuOpen && !this.$refs.sidebar.contains(event.target) && !event.target.closest('.hamburger-menu')) {
        this.isMenuOpen = false;
        this.$refs.menuCheckbox.checked = false;
        console.log("yes")
      }
    },
    closeMenu() {
      this.isMenuOpen = false;
      if (this.$refs.menuCheckbox) {
        this.$refs.menuCheckbox.checked = false;
      }
      document.removeEventListener('click', this.handleClickOutside);
    },
    onImgClick() {
      const token = this.$route.query.token;
      console.log('Token:', token);  // Verify that the token is correctly logged
      if (token) {
        this.$router.push({
          path: '/',        // Specify the new path here
          query: { token: token }   // Include the token as a query parameter
        });
      } else {
        console.error('Token is undefined or missing');
      }
    },
    async onGoTo(destination) {
      switch (destination) {
        case "tickets":
          this.isAboutUs       = false;
          this.isArtists       = false;
          this.isCommunity     = false;
          this.isTickets       = true;
        break;

        case "about-us":
          this.isAboutUs       = true;
          this.isArtists       = false;
          this.isCommunity     = false;
          this.isTickets       = false;
        break;

        case "artists":
          this.isAboutUs       = false;
          this.isArtists       = true;
          this.isCommunity     = false;
          this.isTickets       = false;
        break;
    
        default:
          this.isAboutUs       = false;
          this.isArtists       = false;
          this.isCommunity     = false;
          this.isTickets       = false;
        break;
      }
    },
    showTooltip() {
      this.tooltipVisible = true;
      console.log(this.tooltipVisible)
      // Set a timeout to hide the tooltip after 2 seconds
      setTimeout(() => {
        this.tooltipVisible = false;
      console.log(this.tooltipVisible)

      }, 2000);
    }
  },
  async created() {
    switch (this.$route.path) {
      case "/":
        this.isAboutUs       = false;
        this.isArtists       = false;
        this.isCommunity     = false;
        this.isTickets       = true;
        break;

      case "/about-us":
        this.isAboutUs       = true;
        this.isArtists       = false;
        this.isCommunity     = false;
        this.isTickets       = false;
        break;

      case "/artists":
        this.isAboutUs       = false;
        this.isArtists       = true;
        this.isCommunity     = false;
        this.isTickets       = false;
        break;

      case "/personal":
        this.isAboutUs       = false;
        this.isArtists       = false;
        this.isCommunity     = false;
        this.isTickets       = false;
        break;
    
      default:
        this.isAboutUs       = false;
        this.isArtists       = false;
        this.isCommunity     = false;
        this.isTickets       = false;
        break;
    }
  },
}
</script>

<style scoped>

:root {
  --bar-width: 60px;
  --bar-height: 8px;
  --hamburguer-gap: 6px;
  --foreground: #333;
  --background: white;
}

.hamburger-menu {
  display: flex;
  flex-direction: column;
  gap: 4px;
  position: absolute;
  top: 22px;
  right: 2rem;
  z-index: -1;
  cursor: pointer;
  visibility: 0;
  opacity: 0;
  padding: 9px 14px 19px 26px;
}

.hamburger-menu:hover {
  background: none;
}

.hamburger-menu:before,
.hamburger-menu input {
  content: "";
  width: 8px;
  height: 8px;
  border-radius: 999px;
  background-color: #f1f1f1;
  transform-origin: left center;
  transition: opacity 250ms ease-in-out, width 250ms ease-in-out, rotate 250ms ease-in-out, translate 250ms ease-in-out, background-color 250ms ease-in-out;
}

.hamburger-menu:before {
  translate: -12px 12px;
}

.hamburger-menu input {
  appearance: none;
  padding: 0;
  margin: 0;
  outline: none;
  pointer-events: none;
}
/* 
.hamburger-menu:has(input:checked)::before {
  rotate: 45deg;
  width: calc(24 * 1.41421356237);
  translate: 0px -2.5px;
}

.hamburger-menu:has(input:checked)::after {
  rotate: -45deg;
  width: calc(24 * 1.41421356237);
  translate: 0px 2.5px;

}

.hamburger-menu input:checked {
  opacity: 0;
  width: 0;
} */

.sidebar {
  transition: translate 250ms ease-in-out;
  display: flex;
  flex-direction: column;
  position: fixed;
  translate: 100%;
  z-index: 9;
  right: 0;
  padding-top: 9rem;
  padding-right: 0.3rem;
  padding-left: 0.3rem;
  top: 0;
  bottom: 0;
  justify-content: start;
  background: rgba(255, 255, 255, 0); /* Initial transparent background */
  backdrop-filter: blur(10px); /* Apply blur effect */
  visibility: 0;
  opacity: 0;
  overflow: visible;
}



.sidebar.open {
  translate: 0;
}

.nav-bar {
  z-index: 10;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  right: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  margin: 0;
  justify-content: space-between;
  align-items: center;
  min-height: 64px;
  padding: 0px 11rem 0px 8rem;
  gap: auto;
  transition: background-color 0.3s, backdrop-filter 0.3s, transform 400ms cubic-bezier(0.2, 0, 0.25, 1);
  background-color: #08593a;
  min-height: 89px;
}


.img-container {
  width: 180px;
  height: 48px;
}

.nav-logo {
  height: 48px;
  width: 180px;
  cursor: pointer;
}

.nav-items {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  gap: 3rem;
}

.nav-item {
  text-decoration: none;
  color: #fff;
  font-family: "Libre Baskerville", serif;
  font-weight: 400;
  font-style: normal;
  font-size: 10px;
  letter-spacing: 1.8px;
  margin: 0 1px;
}

.nav-item:hover {
  outline: none;
  background: none;
}

router-link,
a,
.hamburger-menu,
.nav-logo {
  -webkit-tap-highlight-color: transparent;
  outline: none;
  -webkit-focus-ring-color: transparent;
}

router-link:focus,
a:focus,
.hamburger-menu:focus,
.nav-logo:focus {
  outline: none;
}

.hamburger-btn {
  background: none; border: none; display: none;
}

.z-10 {
  z-index: 30;
  padding:  20px 8px;
}

.z-11 {
  z-index: 31;
  padding:  20px 8px;
}


.hidden {
  transform: translateY(-100%);
}

.active {
  font-weight: 700;
  color: #e4bf80;
  margin: 0;
}

.disabled {
  opacity: 0.8;
  cursor: pointer;
}

.tooltip {
  content: "Próximamente";
  position: absolute;
  top: 6px;
  background-color: #031b12;
  transform: translateX(124px);
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  letter-spacing: 1px;
  font-size: 12px;
  opacity: 0;
  transition: opacity 2s ease-in-out;
  z-index: 33;
  pointer-events: none;
}

.tooltip.on {
  opacity: 1;
}

@media only screen and (max-width: 1150px) {
  .nav-bar {
    padding: 0 5rem 0 5em;
  }
  .nav-items {
    gap: 2.8rem;
  }
  .tooltip {
    transform: translateX(120px);
  }
}

@media only screen and (max-width: 1000px) {
  .nav-bar {
    padding: 0 2.2rem 0 2.2rem;
  }
  .nav-items {
    gap: 2rem;
  }
  .tooltip {
    transform: translateX(108px);
  }
}

@media only screen and (max-width: 850px) {
  .nav-bar {
    padding: 0 1.3rem 0 1.3rem;
  }
  .nav-items {
    gap: 1.3rem;
  }
  .tooltip {
    transform: translateX(96px);
  }
}


@media only screen and (max-width: 768px) {
  .nav-bar {
    padding: 0 1rem 0 1rem;
  }
  .nav-items {
    display: none;
  }

  .hamburger-menu {
    visibility: visible;
    opacity: 1;
    z-index: 100;
  }

  .sidebar {
    visibility: visible;
    overflow: visible;
    opacity: 1;
  }

  .nav-item {
    color: #172d1b;
  }  

  .active {
    font-weight: 700;
    color: #a87f38;
    margin: 0;
  }

  .tooltip {
  content: "Próximamente";
  position: absolute;
  right: 6px;
  transform: translateY(176px);
  background-color: #031b12;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  letter-spacing: 1px;
  font-size: 12px;
  opacity: 0;
  transition: opacity 2s ease-in-out;
  z-index: 33;
  pointer-events: none;
}

  .disabled {
  opacity: 0.8;
  cursor: pointer;
  position: relative;
  z-index: 32;
}
}

</style>