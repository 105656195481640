<template>
  <div>
    <h1>Unauthorized</h1>
    <p>Please make sure you are using the link that was provided to you. If it still does not work, please communicate with the person that provided you with the link.</p>
  </div>
  <div>
    <h1>No autorizado</h1>
    <p>Asegúrese de estar utilizando el enlace que se le proporcionó. Si aún no funciona, comuníquese con la persona que le proporcionó el enlace.</p>
  </div>  
</template>

<script>

export default {
  name: "ErrorView"
}
</script>

<style scoped>
div {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

div p {
  max-width: 500px;
  margin: 0 32px 0 32px;
}
</style>