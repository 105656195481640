<template>
  <div>
    <!-- Main Nav-Bar -->
    <nav :class="{ hidden: isMainNavHidden }" class="main-navbar">
      <!-- Main nav-bar content -->
      <router-link to="/product">Product</router-link>
      <router-link to="/other-page">Other Page</router-link>
    </nav>

    <!-- Sub-Nav-Bar -->
    <nav :class="{ 'fixed-top': isMainNavHidden }" class="sub-navbar">
      <!-- Sub-navbar content -->
      <a href="#specifications" :class="{ active: activeSection === 'specifications' }" @click="scrollToSection('specifications')">Specifications</a>
      <a href="#value" :class="{ active: activeSection === 'value' }" @click="scrollToSection('value')">Value</a>
      <a href="#history" :class="{ active: activeSection === 'history' }" @click="scrollToSection('history')">History</a>
    </nav>
  </div>
</template>

<script>
export default {
  name: "NavBarTest",
  data() {
    return {
      isMainNavHidden: false,
      lastScrollY: 0
    };
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll() {
      const currentScrollY = window.scrollY;

      if (currentScrollY > this.lastScrollY) {
        // Scrolling down
        this.isMainNavHidden = true;
      } else {
        // Scrolling up
        this.isMainNavHidden = false;
      }

      this.lastScrollY = currentScrollY;
    },
    scrollToSection(sectionId) {
      const element = document.getElementById(sectionId);
      if (element) {
        const topOffset = this.isMainNavHidden ? 60 : 120; // Adjust for main nav height
        const elementPosition = element.getBoundingClientRect().top + window.scrollY;
        const offsetPosition = elementPosition - topOffset;

        window.scrollTo({
          top: offsetPosition,
          behavior: 'smooth'
        });
      }
    }
  }
};
</script>

<style scoped>
.main-navbar {
  position: fixed;
  top: 0;
  width: 100%;
  transition: transform 0.3s ease-in-out;
  z-index: 1000;
}

.sub-navbar {
  position: fixed;
  top: 60px; /* Initial position under main navbar */
  width: 100%;
  transition: top 0.3s ease-in-out;
  z-index: 999;
}

.fixed-top {
  top: 0; /* Moves the sub-navbar to the top when main navbar is hidden */
}

.hidden {
  transform: translateY(-100%);
}
</style>
