<template>
  <div class="whole-container">
    
    <div class="nav-bar">
      <div class="nav-logo">
        <img src="../assets/img/icon/LOGO ARE TRUST(Verde)-V 1.png" />
      </div>
      <div class="btn-container">
        <button @click="onLogout" class="btn">Cerrar Sesión</button>
      </div>
    </div>

    <div class="content-container">
      <div class="card-container">
        <h3 class="title">Usuario Creado</h3>
        <div class="data-container">
          <label class="label" for="username">Link del usuario:</label>
          <p @click="onCopyToClipboard" class="user-url" id="user-url">{{ userUrl }}</p>
        </div>
        <div class="btn-container">
          <button @click="onReturn" class="btn">Volver</button>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import router from "@/router";
import { toast } from "vue3-toastify"

export default {
  name: "BOLinkView",
  data() {
    return {
      userUrl: ""
    }
  },
  methods: {
    async onReturn() {
      router.push({name: "bocreate"})
    },
    async onCopyToClipboard() {
      navigator.clipboard.writeText(this.userUrl);
      this.notify("Link copiado al cortapapeles")
    },
    async onLogout() {
      try {
        await this.$axios.post("/logout")
      } catch (error) {
        console.log(error.response.status)
        if (error.response.status == 500 || error.response.status == 501) {
          console.log(error)
          this.notify("Un error ocurrió, intenta de nuevo más tarde")
          return;
        }
        if (error.response.status == 401 || error.response.status == 403) {
          this.notify("No tienes acceso")
          this.$router.push({name: "bohome"})
          console.log(error)
          return;
        }
      }

      router.push({name: "bohome"})
    },
    notify(message) {
      toast(message, {
        position: toast.POSITION.TOP_RIGHT,
        transition: toast.TRANSITIONS.FLIP,
        toastClassName: "my-toast"
      })
    }
  },
  created() {
    this.userUrl = this.$route.query.userUrl;
  }
}

</script>

<style scoped>

.whole-container {
  margin: 0;
  display: flex;

  height: 100vh;
  flex-direction: column-reverse;
  background-color: #f8f8f8;
  color: #001A0D;

  font-family: "Roboto", sans-serif;
  overflow: hidden;
}

.nav-bar {
  display: flex;
  height: 80px;
  z-index: 10;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  flex-direction: row;
  margin: 0;
  justify-content: space-between;
  align-items: center;
  padding: 0 4.5rem 0 4.5rem;
  border-bottom: 1px solid #001A0D;
}

.content-container {
  width: 100%;
  margin: 0;
  height: calc(100% - 80px);
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.card-container {
  display: flex;
  flex-direction: column;
  gap: auto;
  height: 300px;
  margin-top: 40px;
  padding: 24px 16px 40px 16px;
  box-shadow: 0px 0px 16px #001a0d63;
  width: 300px;
  justify-content: space-between;
}

.title {
  align-self: center;
  text-align: center;
  font-weight: 400;
  font-size: 24px;
}

.data-container {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.label {
  font-size: 16px;
  color: #18271f;
}

.user-url {
  padding: 8px 0px;
  font-weight: 600;
  margin: 0;
  cursor: pointer;
}

.btn-container {
  text-align: center;
}

.btn-container .btn {
  font-size: 18px;
  padding: 12px 24px;
  border-radius: 999px;
  border: 2px solid #001A0D;
  background: none;
  transition: 120ms;
}

.btn-container .btn:hover {
  cursor: pointer;
  background-color: #001A0D;
  color: #f8f8f8;
  border: 2px solid #001A0D;
  padding: 12px 28px;
}

@media only screen and (max-width: 400px) {
  .content-container {
    padding: 0 16px;
  }

  .card-container {
    margin-right: 32px;
    box-shadow: 0px 0px 10px #001a0d63;

  }
}

</style>