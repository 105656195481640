<template>
  <NavBar :isHidden="isMainNavHidden"/>
  <div :class="{'fixed-top-big': isMainNavHidden}" class="transition-padding"></div>
  <nav :class="{'fixed-top': isMainNavHidden}" class="secondary-navbar">
    <div class="nav-items-container">
      <button
        :class="{ active: activeSection === 'enfoque' }"
        @click="scrollToSection('enfoque')"
      >
        Enfoque
      </button>
      <button
        :class="{ active: activeSection === 'alta-joyeria' }"
        @click="scrollToSection('alta-joyeria')"
      >
        Alta Joyería
      </button>
      <!-- <button
        :class="{ active: activeSection === 'valor' }"
        @click="scrollToSection('valor')"
      >
        Valor
      </button> -->
      <button
        :class="{ active: activeSection === 'equipo' }"
        @click="scrollToSection('equipo')"
      >
        Equipo
      </button>
      <button
        :class="{ active: activeSection === 'operacion' }"
        @click="scrollToSection('operacion')"
      >
        Operación
      </button>
    </div>
  </nav>


  <div class="articles-container" :style="{ backgroundColor: '#ffffff', color: '#000000' }">

    <div class="home-section-background">
    <article class="article home" id="hero">
      <div class="content">
        <div class="title-container">
          <div class="title big green">INVIERTE CON</div>
          <div class="title medium green">GARANTÍA EN LAS</div>
          <div class="title big green">ESMERALDAS</div>
          <div class="title medium green">MÁS VALORADAS</div>
          <div class="title big green">DEL MUNDO</div>
        </div>
      </div>
    </article>
    </div>
    <article class="article market" id="enfoque">
      <div class="rock-container">
        <img class="rock-img" src="../assets/img/background/bg_desktop_enfoque_02.jpg" />
      </div>
        <div class="content">
          <div class="title-container">
            <div class="logo-container">
              <img src="../assets/img/icon/SIMBOLO_ARE_TRUST_BLACK_GREEN_4.png" class="logo-single">
            </div>
            <div class="title small">COLOMBIA: </div>
            <div class="title small invisible">-</div>

            <div class="title small">UNA TIERRA PRECIOSA DE</div>
            <div class="title big">OPORTUNIDADES</div>
            <div class="title medium">INEXPLORADAS</div>
          </div>
          <div class="text-container">
            <p class="regular-text">Colombia es rica en recursos naturales como el oro y el petróleo. Sin embargo, gran parte de esta riqueza no beneﬁcia a las comunidades locales. Mientras los inversionistas extranjeros aprovechan estas oportunidades, muchos colombianos enfrentan desafíos económicos y sociales, porque la riqueza de nuestra tierra les sigue siendo inaccesible.</p>
            <p class="regular-text b">¿Y si pudieras ser parte del cambio y beneﬁciarte también de esta riqueza única?</p>
          </div>
      </div>
    </article>

    <article class="article high" id="alta-joyeria">
      <div class="lace-container">
        <img class="lace-img" src="../assets/img/background/bg_desktop_alta_joyeria_03.jpg" />
      </div>
      <div class="lace-container-mobile">
        <img class="lace-img-mobile" src="../assets/img/background/bg_alta_joyeria_03.jpg" />
      </div>
        <div class="content">
          <div class="title-container">
            <div class="logo-container">
              <img src="../assets/img/icon/SIMBOLO_ARE_TRUST_BLACK_GREEN_4.png" class="logo-single">
            </div>
            <div class="title small">EL TESORO</div>
            <div class="title big">MÁS VALIOSO</div>
            <div class="title medium">DEL MUNDO</div>
          </div>
          <div class="text-container">
            <p class="regular-text">A lo largo de la historia, las Esmeraldas han sido símbolos de sanación, fertilidad y amor. Son el único mineral asociado al aspecto femenino y al chakra del corazón. Cleopatra, por ejemplo, las convirtió en emblema de su corona.</p>
            <p class="regular-text b">Pero pocos saben que Colombia alberga las Esmeraldas más valiosas del mundo.</p>
            <!-- <div class="two-line"><p class="bold-text">No-Oil (Sin-Aceite):</p><p class="regular-text ">$50,000 - $150,000 USD por Quilate</p></div> -->
            <p class="regular-text"><span class="b">Formación Única:</span> A diferencia de las Esmeraldas de Brasil, Afganistán y Zambia, que se forman en zonas volcánicas, las nuestras son producto de milenios bajo extrema presión tectónica y contacto con agua subterránea, otorgándoles características únicas.</p>
            <p class="regular-text"><span class="b"> Valor Incomparable:</span> Producto de estas condiciones exclusivas, en Boyacá se producen Esmeraldas <a href="https://www.baunat.com/es/esmeralda-verde#:~:text=El%20valor%20de%20una%20esmeralda,-Al%20igual%20que&text=Algunas%20esmeraldas%20son%20aproximadamente%2020,el%20tono%20de%20la%20esmeralda." target="_blank">20 veces más escasas que los diamantes.</a> Las gemas de calidad pueden valorizarse desde un 400% en los mercados internacionales.</p>
          </div>
      </div>
    </article>

    <article class="article market" id="enfoque">
      <div class="rock-container">
        <img class="rock-img" src="../assets/img/background/bg_desktop_enfoque_02.jpg" />
      </div>
        <div class="content">
          <div class="title-container">
            <div class="logo-container">
              <img src="../assets/img/icon/SIMBOLO_ARE_TRUST_BLACK_GREEN_4.png" class="logo-single">
            </div>
            <div class="title small">APOYANDO A</div>
            <div class="title big">LOS PEQUEÑOS</div>
            <div class="title medium">MINEROS</div>
          </div>
          <div class="text-container">
            <p class="regular-text">Los pequeños mineros, muchos de ellos de segunda y tercera generación en el territorio, <a href="https://www.elcolombiano.com/colombia/mineros-artesanales-muzo-boyaca-buscan-esmeraldas-en-sobras-multinacional-OG25012509" target="_blank">no han tenido la oportunidad de acceder a los mercados internacionales para comercializar a un precio justo</a> las Esmeraldas obtenidas con su arduo trabajo. <span class="b">En su lugar han tenido que aceptar pagos muy bajos</span> que no corresponden con el valor real de estas, poniendo en riesgo el sustento de cientos de familias.</p>
            <p class="regular-text"><span class="b">ARE Trüst está</span> cambiando esta realidad al brindar un modelo que mientras <span class="b">recompensa a los inversionistas, también empodera a los mineros locales,</span> dándoles acceso a condiciones justas en las que puedan prosperar, asegurando así el <span class="b">Bien Común</span></p>
          </div>
      </div>
    </article>

    <!-- <div class="containers-section-background">
    <article class="article containers" id="valor">

    </article>
    </div> -->

    <article class="article team" id="equipo">
      <div class="lace2-container">
        <img class="lace2-img" src="../assets/img/background/bg_nuestro_equipo_05.jpg" />
      </div>
        <div class="content">
          <div class="title-container">
            <div class="logo-container">
              <img src="../assets/img/icon/SIMBOLO_ARE_TRUST_BLACK_GREEN_4.png" class="logo-single">
            </div>
            <div class="title small">LA ALIANZA</div>
            <div class="title big">ENTRE FAMILIAS</div>
            <div class="title small">MINERAS Y ARE TRÜST</div>
          </div>
          <div class="text-container">
            <p class="regular-text">ARE Trüst nació como una alianza que se convirtió en una <span class="b">sociedad conjunta con</span> <a href="https://esmicolombiagroup.com/">Esmicolombia Group SAS,</a> una organización de familias mineras con más de 30 años de experiencia.</p>
            <p class="regular-text"><span class="b">Acceso Directo al Mercado Internacional:</span> Juntos, rompemos barreras y <a href="https://drive.google.com/file/d/1jJI32S5ZsphfIBNF5NnGQKsGPBTBUNeZ/view">conectamos directamente tanto a mineros como a inversionistas</a> con los mercados globales.</p>
            <p class="regular-text">Modelo de Inversión con Garantía Real: Brindamos una inversión respaldada en Esmeraldas certiﬁcadas en Estados Unidos y avaluadas para el mercado internacional, garantizando transparencia y seguridad sobre su valor real.</p>
          </div>
      </div>
    </article>

    <article class="article high">
      <div class="lace-container">
        <img class="lace-img" src="../assets/img/background/bg_desktop_alta_joyeria_03.jpg" />
      </div>
      <div class="lace-container-mobile">
        <img class="lace-img-mobile" src="../assets/img/background/bg_alta_joyeria_03.jpg" />
      </div>
        <div class="content">
          <div class="title-container">
            <div class="logo-container">
              <img src="../assets/img/icon/SIMBOLO_ARE_TRUST_BLACK_GREEN_4.png" class="logo-single">
            </div>
            <div class="title small">EL VALOR INTERNACIONAL</div>
            <div class="title medium">DE LAS ESMERALDAS</div>
            <div class="title medium">COLOMBIANAS</div>
            <div class="title medium">NATURALES</div>
          </div>
          <div class="text-container">
            <p class="regular-text">En ARE Trüst solo trabajamos con Esmeraldas naturales, libres de tratamientos de aceite. Estas gemas, tipo exportación, son las más valoradas¹ en el mercado internacional por su singular belleza natural.</p>
            <div class="two-line"><p class="bold-text">Máxima Calidad (Extra Finas)</p><p class="regular-text "><a href="https://gemstock.org/product/unikalnyy-kolumbiyskiy-izumrud-887-karat-bez-masla-muzo-grs/">Desde $15.000 USD hasta $150.000 USD por Quilate</a></p></div>
            <div class="two-line"><p class="bold-text">Alta Calidad (Finas)</p><p class="regular-text "><a href="https://gemstock.org/product/kolumbiyskiy-izumrud-259-karata-grs/">Desde $5.000 USD hasta $45,000 USD por Quilate</a></p></div>
            <div class="regular-text"><div class="horizontal-line"></div></div>
            <div class="bottom-container"><p class="label-text">¹ Los precios se estiman dentro de un rango que varía según el tamaño de las gemas. A mayor tamaño, mayor es el precio por Quilate.</p></div>
          </div>  
      </div>
    </article>

      <article class="article model short-model" id="operacion-first" ref="penultimateSection">
      
        <div class="content">
          <div class="title-container">
            <div class="logo-container">
              <img src="../assets/img/icon/SIMBOLO_ARE_TRUST_BLACK_GREEN_4.png" class="logo-single">
            </div>
            <div class="title medium">MODELO DE</div>
            <div class="title big">OPERACIÓN</div>
            <div class="horizontal-line horizontal-line-margin"></div>
            <div class="centered-text">Entrega en 8 Semanas después de realizada la inversión</div>
          </div>
          <div class="content-content">
            <div class="points-container">
            <div class="left-side">
              <div class="set-container">
                <p class="bold">1. Producción:</p>
                <div class="data-container">
                  <div class="icon-placeholder"><img style="max-width: 100%; max-height: 100%" src="../assets/img/icon/checkmark_are.svg" /></div>
                  <p class="check-text">Compra de Esmeraldas en Bruto<span class="time-label first-label">1 semana</span></p>
                </div>
                <div class="data-container">
                  <div class="icon-placeholder"><img style="max-width: 100%; max-height: 100%" src="../assets/img/icon/checkmark_are.svg" /></div>
                  <div class="check-text"><p class="text">Lapidación para convertirlas <br> en gemas</p><span style="font-size: 12px;" class="time-label second-label">3 semanas</span></div>
                </div>
              </div>
              <div class="set-container">
                <p class="bold">2. Certificación:</p>
                <div class="data-container">
                  <div class="icon-placeholder"><img style="max-width: 100%; max-height: 100%" src="../assets/img/icon/checkmark_are.svg" /></div>
                  <p class="check-text">En el <a href="https://www.gia.edu/">Instituto Gemológico de <br> Estados Unidos,</a> de Nueva York<span class="time-label fourth-label">2 semanas</span></p>
                </div>
              </div>
              <div class="set-container">
                <p class="bold">3. Avalúo por Perito Acreditado: <span style="font-size: 12px;" class="time-label fifth-label">1 semana</span></p>
              </div>
            </div>
            <div class="right-side">
              <div class="set-container">
                <p class="bold">4. Custodia:</p>
                <div class="data-container">
                  <div class="icon-placeholder"><img style="max-width: 100%; max-height: 100%" src="../assets/img/icon/checkmark_are.svg" /></div>
                  <p class="check-text">En la ubicación preferida por el <br> Inversionista, sea en las oﬁcinas de <br> Esmicolombia Group en Bogotá <br> o en cualquier ciudad de Colombia<span class="time-label seventh-label">1 semana</span></p>
                </div>
              </div>
              <div class="set-container">
                <p class="bold">5. Recompra:</p>
                <div class="data-container">
                  <div class="icon-placeholder"><img style="max-width: 100%; max-height: 100%" src="../assets/img/icon/checkmark_are.svg" /></div>
                  <p class="check-text italic">Conoce nuestra Oferta de Tickets.</p>
                </div>
              </div>
            </div>
            </div>
            <!-- <div class="btn-container">
              <button @click="onKnowMore()" class="btn">QUIERO SABER MÁS</button>
            </div> -->
          </div>
        </div>
    </article>

    <article class="article model low" id="operacion" ref="lastSection">
        <div class="footer-container">
          <img class="footer-img" src="../assets/img/background/bg_modelo_negocio_06.jpg" />
        </div>
        <div class="content">
          <div class="title-container">
            <div class="logo-container">
              <img src="../assets/img/icon/SIMBOLO_ARE_TRUST_BLACK_GREEN_4.png" class="logo-single">
            </div>
            <div class="title medium">CONOCE NUESTROS</div>
            <div class="title medium">TICKETS RESPALDADOS</div>
            <div class="title big">EN ESMERALDAS  </div>
          </div>
          <div class="text-container">
            <p class="regular-text">El valor de las Esmeraldas no es como el valor del dinero, que se devalúa y desaparece con el paso del tiempo o con los cambios políticos. Es un valor que ha permanecido y seguirá permaneciendo con el paso de los años.</p>
            <p class="regular-text">Por eso, en ARE Trüst te brindamos la oportunidad de <span class="b">respaldar y rentabilizar tu dinero en un activo tangible y de creciente valor</span> <a href="https://www.piat.com/en/emeralds-of-exception-an-ever-growing-value/#">(promedio 15% anual en el mercado internacional):</a> las Esmeraldas colombianas.</p>
            <p class="regular-text">Esto signiﬁca que, una vez realices tu inversión por medio de nuestros Tickets, <span class="b">tendrás en tus manos un activo</span> que se aprecia con el tiempo, y en el momento en que <span class="b">decidas monetizar</span> tus Esmeraldas, <span class="b">te las Recompramos</span> al precio del avalúo inicial, más la respectiva <span class="b">valorización.</span></p>

          </div>
            <div class="btn-container">
              <button @click="onKnowMore()" class="btn">OFERTA DE TICKETS</button>
            </div>
        </div>
    </article>

  </div>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
import SmoothScroll from 'smooth-scroll';
// @ is an alias to /src
//import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'HomeView',
  data() {
    return {
      activeSection: "",
      isMainNavHidden: false,
      lastScrollY: 0
    }
  },
  components: {
    NavBar
  },
  mounted() {
    this.scroll = new SmoothScroll();
    window.addEventListener("scroll", this.onScroll);
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeDestroy() {
    this.cleanup();
  },
  beforeRouteLeave(to, from, next) {
    this.cleanup();
    next();
  },
  methods: {
    scrollToSection(sectionId) {



      
      const element = document.getElementById(sectionId);
      if (element) {
        const currentScrollY = window.scrollY;
        const elementPosition = element.getBoundingClientRect().top + window.scrollY;

        let topOffset;
        
        if (elementPosition < currentScrollY) {
          // Scrolling upwards
          topOffset = 134 + 55; // Adjust this value for upward scrolling
          if (sectionId === "alta-joyeria") {
            topOffset = 138;
          }
          //if (sectionId === "valor") {
           // topOffset = 46 + 55;
          //}

          if (sectionId === "equipo") {
            topOffset = 46 + 55;
          }
        } else {
          // Scrolling downwards
          topOffset = 46 + 55; // Adjust this value for downward scrolling
          if (sectionId === "alta-joyeria") {
            topOffset = 48;
          }
          // if (sectionId === "valor") {
          //   topOffset = -43 + 55;
          // }
          if (sectionId === "equipo") {
            topOffset = -43 + 55;
          }

        }

        const offsetPosition = elementPosition - topOffset;

        this.smoothScroll(offsetPosition, 600);
      }
    },
    
    smoothScroll(targetPosition, duration) {
      const startPosition = window.scrollY;
      const distance = targetPosition - startPosition;
      let startTime = null;

      const animateScroll = (currentTime) => {
        if (!startTime) startTime = currentTime;
        const timeElapsed = currentTime - startTime;
        const progress = Math.min(timeElapsed / duration, 1); // Ensure the progress doesn't exceed 1
        const ease = progress * (2 - progress); // easeOutQuad function

        window.scrollTo(0, startPosition + distance * ease);

        if (timeElapsed < duration) {
          requestAnimationFrame(animateScroll);
        }
      };

      requestAnimationFrame(animateScroll);
    },
    onScroll() {
      const sections = ["enfoque", "alta-joyeria", "equipo", "operacion"];
      sections.forEach(section => {
        const sectionElement = document.getElementById(section);
        const rect = sectionElement.getBoundingClientRect();
        if (rect.top <= window.innerHeight / 2 && rect.bottom >= window.innerHeight / 2) {
          this.activeSection = section;
        }
      })
    },
    handleScroll() {
      

      const currentScrollY = window.scrollY;
      const scrollDifference = Math.abs(currentScrollY - this.lastScrollY);
      
      const heroSection = document.getElementById('hero'); // Assuming your hero section has this ID
      const heroMiddlePosition = heroSection.offsetTop + (heroSection.offsetHeight / 2);

      if (currentScrollY < heroMiddlePosition) {
        // If the top of the screen is above the middle of the hero section, show the navbar
        this.isMainNavHidden = false;
      } else if (scrollDifference > 10) {
        // Otherwise, apply the normal scroll behavior
        if (currentScrollY > this.lastScrollY) {
          // Scrolling down
          this.isMainNavHidden = true;
        } else {
          // Scrolling up
          this.isMainNavHidden = false;
        }
      }

      this.lastScrollY = currentScrollY;
      
    },
    async onKnowMore() {
      const token = this.$route.query.token;
      console.log('Token:', token);  // Verify that the token is correctly logged
      if (token) {
        this.$router.push({
          path: '/',        // Specify the new path here
          query: { token: token }   // Include the token as a query parameter
        });
      } else {
        console.error('Token is undefined or missing');
        this.$router.push({ name: "error" });
      }
    },
    cleanup() {
      window.removeEventListener('scroll', this.onScroll);
      window.removeEventListener('scroll', this.handleScroll);
    }
  },
}
</script>

<style scoped>

#app {
  height:auto;
}

.centered-text {
  max-width: 249px;
  letter-spacing: 0.7px;
  font-size: 16px;
}

.articles-container {
  height: 100%;
  margin: 0;
  scroll-behavior: smooth;
  display: flex;
  flex-direction: column;
  gap: 0;
  padding: 0;
  align-content: center;
  width: 100%;
  text-align: center;
  overflow-x: hidden;
  position: relative;
}

.article {
  height: 715px;
  display: flex;
  margin: 0px auto;
  color: #172d1b !important;
  overflow: hidden;
  position: relative;
}

.home-section-background {
  width: 100%;
}

.secondary-navbar {
  background-color: #fff;
  height: 48px;
  color: black;
  position: fixed;
  top: 89px; 
  width: 100%;
  z-index: 10;
  text-align: center;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.1 );
  transition: top 300ms ease-in-out;
}

.nav-items-container {
  display: flex;
  height: 100%;
  flex-direction: row;
  justify-content: center;
  gap: 20px;
  margin: 0 10px;
}

.nav-items-container button {
  color: #434d44;
  cursor: pointer;
  text-decoration: none;
  background: none;
  border: none;
  margin: 0;
  font-size: 14px;
  transition: color 150ms;
  padding: 14.5px 0;
  font-family: "Libre Baskerville", serif;
}

.nav-items-container button:hover {
  outline: none;
  background: none;
}

router-link,
button {
  -webkit-tap-highlight-color: transparent;
  outline: none;
  -webkit-focus-ring-color: transparent;
}

router-link:focus,
button:focus {
  outline: none;
}

.nav-items-container button.active {
  color: #a87f38;
}

.home {
  max-width: 1440px;
  background-image: url("../assets/img/background/bg_header_desktop_are_01.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: right 20% center;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: start;
  margin-top: 30px; 
}

.transition-padding {
  height: 125px;
  position:fixed;
  width: 100%;
  z-index: 9;
  top: 0px;
  transition: transform 300ms ease-in-out;
  background-color: #08593a;
}

.fixed-top {
  top: 0; /* Moves the sub-navbar to the top when main navbar is hidden */
}

.fixed-top-big {
  transform: translateY(-100%);
}

.logo-container {
  display: flex;
  flex-direction: column;
  justify-content: start;
  height: 52px;
  align-items: end;
}

.logo-single {
  height: 34px;
  width: 34px;
}

.title-container {
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 3px;
}

.text-container {
  display: flex;
  flex-direction: column;
  text-align: start;
  gap: 20px;
}

.regular-text {
  width: 410px;
  text-align:justify;
  font-family: "Libre Baskerville", serif;
  line-height: 17px;
  text-justify:auto;
  font-size: 16px;
  padding: 0;
  margin: 0;
  color: #000 !important;
  z-index: 1;
}

.bold-text {
  max-width: 330px;
  text-align: justify;
  font-size: 15px;
  font-weight: 700;
  padding: 0;
  margin: 0;
}

.two-line {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.horizontal-line {
  width: 100%;
  border-top: 1px solid;
}

.horizontal-line-margin {
  margin-top: 8px;
  margin-bottom: 6px;
}

.home .content {
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  text-align: end;
  align-items: end;
  margin: 0 0 100px 150px;
}

.market {
  overflow: visible;
  height: 500px;
  display: flex;
  padding: 0 0px 0px 0px; 
  margin: 0px auto;
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: center;
  margin-top: 55px;
  margin-bottom: 55px;
  background-color: #fff !important;
}

.market .content {
  display: flex;
  flex-direction: column;
  justify-content: top;
  gap: 32px;
  width: 520px;
  background-color: #fff !important;

}

.market .content .title-container {
  width: 390px;
}

.high {
  overflow: hidden;
  display: flex;
  display: flex;
  width: 1441px;
  flex-direction: row-reverse;
  justify-content: space-between;
}

.high .content {
  display: flex;
  flex-direction: column;
  justify-content: top;
  gap: 32px;
  margin-top: 55px;
  margin-left: 250px;
}

.lace-container {
  height: 613px;
  width: 570px;
  box-sizing: border-box;
  z-index: 0;
}

.lace-img {
  height: 613px;
  width: 570px;
  /* max-height: 100%;
  width: 100%; */
}

.lace2-container {
  height: 280px;
  width: 500px;
  box-sizing: border-box;
  z-index: 2;
}

.lace2-img {
  max-height: 100%;
  width: 100%;
}

.lace-container-mobile {
  height: 265px;
  width: 520px;
  box-sizing: border-box;
  display: none;
}

.lace-img-mobile {
  /* height: 265px;
  width: 520px; */
  max-height: 100%;
  width: 100%;
}

.footer-container {
  height: 305px;
  width: 750px;
  box-sizing: border-box;
  z-index: 0;
  position: absolute;
  bottom: 0;
}

.footer-img {
  max-height: 100%;
  width: 100%;
  position: relative;
  object-fit: cover;
  bottom: 0;
  z-index: 0;
}

.invisible {
  opacity: 0;
}

.containers-section-background {
  width: 100%;
}

.containers {
  max-width: 1540px;
  height: 800px;
  background-image: url("../assets/img/background/bg_cadena_valor_04.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
}

.containers .content {
  display: flex;
  flex-direction: column;
  justify-content: top;
  align-items: center;
  gap: 32px;
  margin-top: 89px;
}

.team {
  overflow: hidden;
  display: flex;
  flex-direction: column-reverse;
  justify-content: start;
  align-items: center;
  margin: 0px auto 0px auto;
  padding: 89px 0 55px 0;
  width: 100%;
  gap: 24px;
}

.content {
  z-index: 1;
}

.team .content {
  display: flex;
  flex-direction: column;
  justify-content: top;
  align-items: center;
  gap: 40px;
}

.model   {
  overflow: hidden;
  display: flex;
  flex-direction: column-reverse;
  justify-content: start;
  align-items: center;
  margin: 89px auto 0 auto;
  padding: 0;
  width: 100%;
  height: 800px;
}

.short-model {
  height: 490px;
}

.model .content {
  display: flex;
  flex-direction: column;
  justify-content: top;
  align-items: center;
  gap: 32px;
}

.content-content {
  display: flex;
  flex-direction: column;
  gap: 48px;
}

.points-container {
  display: flex;
  flex-direction: row;
  gap: 24px;
  justify-content: end;
}


.left-side {
  display: flex;
  flex-direction: column;
  gap: 36px;
}

.right-side {
  display: flex;
  flex-direction: column;
  gap: 36px;
}

.btn-container {
  height: 55px; 
  width: 100%; 
  display: flex;
  z-index: 2;
  flex-direction: column;
  justify-content: center;
}

.btn {
  font-size: 19px;
  border: none;
  padding: 12px 170px;
  box-sizing: border-box;
  color: white;
  background-color: #08593a;
  font-family: "Libre Baskerville", serif;
  font-style: normal;
  transition: 100ms;
  margin: auto;
}

.btn:hover {
  background-color: #063b27;
}

.set-container {
  margin: 0 auto;
  padding: 0;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  max-width: 344px;
  gap: 4px;
  position: relative;
}

.set-container .bold {
  font-weight: 700;
  text-align: start;
  min-width: 350px;
  margin: 0 auto;
}

.data-container {
  display: flex;
  flex-direction: row;
  justify-content: start;
  margin-left: 20px;
  margin: 0 auto;
  font-family: "Libre Baskerville", serif;
}

.close-checkmarks-containers {
  display: flex;
  flex-direction: column;
  margin-top: 8px;
}

.data-container .icon-placeholder {
  width: 12px;
  height: 12px;
  padding: 0px 10px;
}

.data-container .check-text {
  text-align: start;
  padding: 0;
  margin-top: 0;
  margin-bottom: 0;
  width: 320px;
}

.data-container .check-text .text {
  display: inline;
  max-width: 200px;
}



.first-label {
  position: absolute;
  translate: 0px 3px;
  right: 0;
}

.second-label {
  position: absolute;
  translate: 0px 3px;
  right: 0;
}

.third-label {
  position: absolute;
  translate: 0px 3px;
  right: 0;
}

.fourth-label {
  position: absolute;
  translate: 0px 3px;
  right: 0;
}

.fifth-label {
  position: absolute;
  translate: 0px 3px;
  right: 0;
}

.sixth-label {
  position: absolute;
  translate: 0px 3px;
  right: 0;
}

.seventh-label {
  position: absolute;
  translate: 0px 3px;
  right: 0;
}

.eigth-label {
  position: absolute;
  translate: 0px 3px;
  right: 0;
}

.ninth-label {
  position: absolute;
  translate: 0px 3px;
  right: 0;
}

.tenth-label {
  position: absolute;
  translate: 0px 3px;
  right: 0;
}

.bottom-container {
  text-align: start; 
  width: 410px;
  transform: translateY(-20px);
}

.bottom-container .label-text {
  font-size: 13px;
}

.bottom-container .label-text .bold {
  font-weight: 700;
}

.title {
  color: #172d1b;
  font-family: "Libre Baskerville", serif;
  display: inline;
  font-weight: 400;
  font-style: normal;
}

.img-container {
  display: flex;
  height: 30px;
  align-items: end;
}

.rock-container {
  height: 455px;
  width: 525px;
  box-sizing: border-box;
  margin-top: 18px;
  margin-right: 14px;
  z-index: 2;
}

.rock-img {
  height: 455px;
  width: 525px;
  /* max-height: 100%;
  width: 100%; */
}

.smallest {
  font-size: 16px;
}

.small {
  font-size: 20px;
}

.medium {
  font-size: 26px;
}

.big {
  font-size: 36px;
  line-height: 38px;
}

.b {
  font-weight: 700;
}

.mr-1 {
  margin-right: 4px;
}


.time-label {
  display: inline !important;
  font-style: italic !important;
  font-size: 12px !important;
  font-weight: 400;
  font-family: "Libre Baskerville", serif;
  text-justify:auto;

  font-style: normal;

}

.not-regular-text-width {
  width: 304px;
}

.sticky {
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  height: 305px;
  width: 750px;
  object-fit: cover;
}

@media only screen and (max-width: 1440px) {
  .high {
    width: 100%;
  }
}

@media only screen and (max-width: 1200px) {
  .home .content {
    margin-left: 100px;
    margin-bottom: 200px;
  }
  .high {
    width: 100%;
  }
}

@media only screen and (max-width: 1050px) {
  .lace-container,
  .lace-img {
    height: 527px;
    width: 490px;
  }
}

@media only screen and (max-width: 960px) {
  .market {
    flex-direction: column-reverse;
    align-items: center;
    padding-bottom: 205px;
  background-color: #fff !important;

  }

  .market .content {
    width: 390px;
  }

  .rock-container {
    height: 345px;
    width: 400px;
    position: relative;
  }
  .rock-img {
    height: 345px;
    width: 400px;
    position: absolute;
    transform: translateX(-110px);
  }

  .high {
    flex-direction: column;
    align-items: center;
    justify-content: start;
    height: 880px;
    padding-top: 48px;
  }

  .high .content {
    margin: 0;
  }

  .lace-container {
    display: none;
    margin: 0 auto 0 auto;
  }

  .lace-container-mobile {
    display: inline-block;
  }
}

@media only screen and (max-width: 900px) {
  .home .content {
    margin-left: 50px;
    margin-bottom: 300px;

  }
}

@media only screen and (max-width: 850px) {
  .home .content {
    margin-left: 24px;
    margin-bottom: 350px;
  }

  .model {
    height: 800px;
  }

  .short-model {
  height: 690px;
}

  .points-container {
    flex-direction: column;
    gap: 36px;
  }


  .btn {
    padding: 12px 100px;
  }

  .footer-container {
    height: 265px;
    width: 650px;
  }

  .sticky {
  position: fixed;
  bottom: -5px;
  height: 265px;
  width: 650px;
}

}

@media only screen and (max-width: 720px) {
  .home .content {
    margin-left: 24px;
    margin-bottom: 450px;
  }
}

@media only screen and (max-width: 640px) {
  .home {
  background-image: url("../assets/img/background/bg_header_are_01.jpg");
  justify-content: center;
  }

  .home .content {
    margin-left: 0px;
    margin-bottom: 440px;
    align-items: center;
  }

  .model {
    height: 800px;

  }

  .short-model {
  height: 690px;
}

  .btn {
    padding: 12px 48px;
  }

  .footer-container {
    height: 167px;
    width: 410px;
  }

  .sticky {
  position: fixed;
  height: 167px;
    width: 410px;
}
}

@media only screen and (max-width: 540px) {
  .market .content {
    width: 340px;
  background-color: #fff !important;

  }

  .market .content .title-container {
    width: 340px;
  }

  .small {
    font-size: 18px;
  }

  .medium {
  font-size: 20px;
  }

  .big {
    font-size: 35px;
  }

  .high {
    padding-top: 0;
  }

  .high .content {
    width: 340px;
  }

  .high .content .title-container {
    width: 340px;
  }

  .regular-text {
    width: 340px;
    font-size: 15px;
    line-height: 17px;
  }

  .bottom-container {
    width: 340px;
  }

  .set-container .bold,
  .data-container .check-text {
    width: 310px;
  }

  .rock-container {
    height: 216px;
    width: 259px;
    position: relative;
  }
  .rock-img {
    height: 216px;
    width: 250px;
    position: absolute;
    transform: translateX(-40px);
  }

  .lace-container-mobile {
    height: 209px;
    width: 410px;
  }

  .lace2-container {
    height: 224px;
    width: 400px;
  }
}

@media only screen and (max-width: 420px) {

  .home .content {
    margin-bottom: 410px;
  }

  .nav-items-container {
    display: flex;
    height: 100%;
    flex-direction: row;
    justify-content: space-between;
    gap: 0;
    margin: 0 12px;
  }

  .nav-items-container button {
    color: #172d1b;
    cursor: pointer;
    text-decoration: none;
    margin: 0;
    font-size: 13px;
    transition: color 150ms;
    padding: 14.5px 0;
  }
}

.italic {
  font-style: italic;
}

.green {
  color: #005a39;
}



</style>
