<template>
  <div class="whole-container">
    
    <div class="nav-bar">
      <div class="nav-logo">
        <img src="../assets/img/icon/LOGO ARE TRUST(Verde)-V 1.png" />
      </div>
      <div class="btn-container">
        <button @click="onLogout" class="btn-nav">Cerrar Sesión</button>
      </div>
    </div>

    <div class="content-container">
      <div class="card-container">
        <h3 class="title">Nuevo Usuario</h3>
        <p class="explain-text">Al crear un usuario, se le otorgará un link el cual dicha persona debe de usar para tener autorización y ser identificado dentro de la aplicación.</p>
        <div class="fields-container">
          <div class="field">
            <label class="label" for="username">Nombre del usuario</label>
            <input v-model="name" type="text" autocomplete="off" class="input" id="username" required>
          </div>
          <div class="field">
            <label class="label" for="username">Precio de ticket (opcional)</label>
            <input v-model="ticketCost" type="text" autocomplete="off" class="input" id="username" required placeholder="8.000">
          </div>
          <div class="field">
            <label class="label" for="username">Porcentaje de retorno mensual (opcional)</label>
            <input v-model="returnPercentage" type="text" autocomplete="off" class="input" id="username" required placeholder="8">
          </div>
        </div>
        <div class="btn-container">
          <button @click="onCreate" class="btn">Crear</button>
        </div>
      </div>
      <div class="card-container-large">
        <h3 class="title">Configuración</h3>
        <div class="field">
            <label class="label" for="username">Tickets disponibles:</label>
            <div class="ticket-number-container">
              <p class="ticket-number">{{ remainingTickets }}</p>
            </div>
          </div>
        <div class="btn-container-three">
          <div class="btn-container-two">
            <button @click="onReduceRemainingTickets" class="btn-i">
              <MinusIcon class="btn-icon"/>
            </button>
            <button @click="onIncrementRemainingTickets" class="btn-i">
              <AddIcon class="btn-icon"/>
            </button>
          </div>
          <div class="btn-container">
            <button @click="onSaveRemainingTickets" class="btn">Guardar</button>
          </div>
        </div>
        <div class="field second">
            <label class="label" for="username">Días de oferta personal:</label>
            <div class="ticket-number-container">
              <p class="ticket-number">{{ currentDays }}</p>
            </div>
          </div>
        <div class="btn-container-three">
          <div class="btn-container-two">
            <button @click="onReduceDays" class="btn-i">
              <MinusIcon class="btn-icon"/>
            </button>
            <button @click="onIncrementDays" class="btn-i">
              <AddIcon class="btn-icon"/>
            </button>
          </div>
          <div class="btn-container">
            <button @click="onSaveNewDays" class="btn">Guardar</button>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import router from "@/router";
import { toast } from "vue3-toastify"
import MinusIcon from "@/components/icon/MinusIcon.vue";
import AddIcon from "@/components/icon/AddIcon.vue";

export default {
  name: "BOCreateView",
  components: {
    MinusIcon,
    AddIcon
  },
  data() {
    return {
      name: "",
      remainingTickets: null,
      maxRemainingTickets: 125,
      maxDays: 30,
      currentDays: null,
      ticketCost: "",
      returnPercentage: ""
    }
  },
  methods: {
    async onCreate() {
      if(this.name === "") {
        this.notify("Nombre de usuario no puede estar vacío")
        return;
      }
      if (this.name.length < 3) {
        this.name = ""
        this.notify("Nombre de usuario debe de tener al menos 3 caracteres")
        return;
      }
      let link
      try {
        const res = await this.$axios.post("/users", {
          name: this.name,
          ticketCost: this.ticketCost,
          returnPercentage: this.returnPercentage
        })
        link = res.data.url
      } catch (error) {
        console.log(error.response.status)
        if (error.response.status == 500 || error.response.status == 501) {
          console.log(error)
          this.notify("Un error ocurrió, intenta de nuevo más tarde")
          return;
        }

        if (error.response.status == 400) {
          this.name = ""
          console.log(error.response)
          this.notify("Nombre de usuario debe de tener al menos 3 caracteres")
          return;
        }

        if (error.response.status == 401 || error.response.status == 403) {
          console.log(error)
          router.push({name: "bohome"})
          return;
        }
        return;
      }

      this.username = ""
      console.log(link)
      router.push({path: "/backoffice/link", query: {userUrl: link}})
    },
    async onLogout() {
      try {
        await this.$axios.post("/logout")
      } catch (error) {
        console.log(error.response.status)
        if (error.response.status == 500 || error.response.status == 501) {
          console.log(error)
          this.notify("Un error ocurrió, intenta de nuevo más tarde")
          return;
        }
        if (error.response.status == 401 || error.response.status == 403) {
          this.notify("No tienes acceso")
          this.$router.push({name: "bohome"})
          console.log(error)
          return;
        }
      }

      router.push({name: "bohome"})
    },
    async onReduceRemainingTickets() {
      if (this.remainingTickets !== 0) {
        this.remainingTickets -= 1
      } else {
        this.notify("Límite alcanzado")
      }
    },
    async onReduceDays() {
      if (this.currentDays !== 0) {
        this.currentDays -= 1
      } else {
        this.notify("Límite alcanzado")
      }
    },
    async onIncrementRemainingTickets() {
      if (this.remainingTickets !== this.maxRemainingTickets) {
        this.remainingTickets += 1;
        return;
      } else {
        this.notify("Límite alcanzado")
      }
    },
    async onIncrementDays() {
      if (this.currentDays !== this.maxDays) {
        this.currentDays += 1;
        return;
      } else {
        this.notify("Límite alcanzado")
      }
    },
    async onSaveRemainingTickets() {
      try {
        await this.$axios.put("/tickets", {
          newTicketsRemaining: this.remainingTickets.toString()
        })
        this.notify("Guardado con éxito")
        return;
      } catch (error) {
        console.log(error.response.status)
        if (error.response.status == 500 || error.response.status == 501) {
          console.log(error)
          this.notify("Un error ocurrió, intenta de nuevo más tarde")
          return;
        }

        if (error.response.status == 401 || error.response.status == 403) {
          console.log(error)
          router.push({name: "bohome"})
          return;
        }
        return;
      }
    },
    async onSaveNewDays() {
      try {
        await this.$axios.put("/timer", {
          newTimer: this.currentDays.toString() * 86400000
        })
        this.notify("Guardado con éxito")
        return;
      } catch (error) {
        console.log(error.response.status)
        if (error.response.status == 500 || error.response.status == 501) {
          console.log(error)
          this.notify("Un error ocurrió, intenta de nuevo más tarde")
          return;
        }

        if (error.response.status == 401 || error.response.status == 403) {
          console.log(error)
          router.push({name: "bohome"})
          return;
        }
        return;
      }
    },
    async getAndUpdateRemainingTickets() {
      try {
        const response = await this.$axios.get("/tickets");
        console.log(response.data)
        this.remainingTickets = parseInt(response.data.ticketsRemaining);
        this.currentDays = parseInt(response.data.currentTimer) / 86400000;
      } catch (error) {
        console.log(error.response.status)
        if (error.response.status == 500 || error.response.status == 501) {
          console.log(error)
          this.notify("Un error ocurrió, por favor refresca la página")
          return;
        }

        if (error.response.status == 401 || error.response.status == 403) {
          console.log(error)
          router.push({name: "bohome"})
          return;
        }
        return;
      }
    },
    notify(message) {
      toast(message, {
        position: toast.POSITION.TOP_RIGHT,
        transition: toast.TRANSITIONS.FLIP,
        toastClassName: "my-toast"
      })
    }
  },
  created() {
    this.getAndUpdateRemainingTickets()
  },
}

</script>

<style scoped>

.whole-container {
  margin: 0;
  display: flex;

  height: 100vh;
  flex-direction: column-reverse;
  background-color: #f8f8f8;
  color: #001A0D;

  font-family: "Roboto", sans-serif;
  overflow: hidden;
}

.nav-bar {
  display: flex;
  height: 80px;
  z-index: 10;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  flex-direction: row;
  margin: 0;
  justify-content: space-between;
  align-items: center;
  padding: 0 4.5rem 0 4.5rem;
  border-bottom: 1px solid #001A0D;
  background-color: #f8f8f8;
}

.content-container {
  width: 100%;
  margin: 0;
  height: calc(100% - 80px);
  display: flex;
  flex-direction: row;
  justify-content: center;
  overflow-y: scroll;
  gap: 56px;
}

.card-container {
  display: flex;
  flex-direction: column;
  gap: auto;
  height: 490px;
  margin-top: 40px;
  padding: 24px 16px 40px 16px;
  box-shadow: 0px 0px 16px #001a0d63;
  width: 300px;
  justify-content: space-between;
}

.card-container-large {
  display: flex;
  flex-direction: column;
  gap: auto;
  height: 490px;
  margin-top: 40px;
  margin-bottom: 40px;
  padding: 24px 16px 40px 16px;
  box-shadow: 0px 0px 16px #001a0d63;
  width: 300px;
  justify-content: space-between;
}

.title {
  align-self: center;
  text-align: center;
  font-weight: 400;
  font-size: 24px;
}

.explain-text {
  font-size: 16px;
  color: #2e3b34;
  margin: 0;
}

.ticket-number-container {
  text-align: center;
  margin: 0;
}

.ticket-number {
  margin: 0;
  font-size: 30px;
  color: #001A0D;
}

.fields-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 16px;
}

.field {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.label {
  font-size: 16px;
  color: #18271f;
}

.input {  
  padding: 8px;
  font-size: 16px;
  border: 2px solid #001A0D;
  transition: 100ms;
}

.input:focus {
  outline: none;
  border-right: 4px solid #001A0D;
}

.btn-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.btn-container-two {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.btn {
  font-size: 18px;
  padding: 12px 24px;
  border-radius: 999px;
  border: 2px solid #001A0D;
  background: none;
  transition: 120ms;
  display: flex;
}

.btn-i {
  font-size: 18px;
  padding: 12px 24px 12px 24px;
  border-radius: 999px;
  border: 2px solid #001A0D;
  background: none;
  transition: 120ms;
  display: flex;
}

.btn-container-three {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.btn-container .btn:hover {
  cursor: pointer;
  background-color: #001A0D;
  color: #f8f8f8;
  border: 2px solid #001A0D;
  padding: 12px 28px;
}

.btn-icon {
  fill: #001A0D; /* Initial fill color */
}

.btn-container-two .btn-i:hover {
  cursor: pointer;
  background-color: #001A0D;
  color: #f8f8f8;
  border: 2px solid #001A0D;
  padding: 12px 28px;
}

.btn-container-two .btn-i:hover .btn-icon {
  fill: #f8f8f8; /* Change fill color on button hover */
}

.btn-container .btn-nav {
  font-size: 18px;
  padding: 12px 24px;
  border-radius: 999px;
  border: 2px solid #001A0D;
  background: none;
  transition: 120ms;
}

.btn-container .btn-nav:hover {
  cursor: pointer;
  background-color: #001A0D;
  color: #f8f8f8;
  border: 2px solid #001A0D;
  padding: 12px 28px;
}

@media only screen and (max-width: 770px) {
  .nav-bar {
    padding: 0rem 1rem 0rem 1rem;
  }

  .content-container {
    padding: 0 16px;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    gap: 0px;
    padding: 0 0 40px 0;
    margin-top: 80px;
  }

  .card-container {
    box-shadow: 0px 0px 10px #001a0d63;
  }

  .btn-container .btn-nav {
    font-size: 16px;
    padding: 10px 16px;
  }
}

.second {
  margin-top: 16px;
}
</style>