<template>
  <NavBarTest />
  <div class="page">
    
  </div>
</template>

<script>
import NavBarTest from '@/components/NavBarTest.vue';

export default {
  name: "TestView",
  components: {
    NavBarTest
  }
}
</script>

<style scoped>
.page {
  height: 2000px;
}
</style>