// src/axios.js
import axios from "axios";

const axiosInstance = axios.create({
  baseURL: "https://api1.aretrust.fund", // Replace with your API base URL
  withCredentials: true, // Ensure cookies are sent with requests
});

// const axiosInstance = axios.create({
//   baseURL: "http://localhost:3000", // Replace with your API base URL
//   withCredentials: true, // Ensure cookies are sent with requests
// });

export default axiosInstance;
