import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import ArtistsView from "../views/ArtistsView.vue";
import TicketsView from "@/views/TicketsView.vue";
import PersonalView from "@/views/PersonalView.vue";
import BOHomeView from "@/views/BOHomeView.vue";
import BORegisterView from "@/views/BORegisterView.vue";
import BOCreateView from "@/views/BOCreateView.vue";
import BOLinkView from "@/views/BOLinkView.vue";

import TestView from "@/views/TestView.vue";

import ErrorView from "@/views/ErrorView.vue";
import NotFoundView from "@/views/NotFoundView.vue";

import axiosInstance from "@/axios";

const routes = [
  {
    path: "/about-us",
    name: "about-us",
    component: HomeView,
    beforeEnter: (to, from, next) => {
      axiosInstance
        .get(`/tokens/${to.query.token}`)
        .then((res) => {
          console.log("res status: ", res.status);
          console.log("res data: ", res.data);
          if (res.status === 200 || res.status === 201) {
            next();
          }
        })
        .catch((err) => {
          switch (err.response.status) {
            case 400:
              console.log(400);
              next({ name: "error" });
              break;

            case 403:
              next({ name: "error" });
              break;

            default:
              next({ name: "error" });
              break;
          }
          console.log(err.response.status);
        });
    },
  },
  // {
  //   path: "/artists",
  //   name: "artists",
  //   component: ArtistsView,
  //   beforeEnter: (to, from, next) => {
  //     console.log(to.query.token);
  //     axiosInstance
  //       .get(`/tokens/${to.query.token}`)
  //       .then((res) => {
  //         console.log("res status: ", res.status);
  //         console.log("res data: ", res.data);
  //         if (res.status === 200 || res.status === 201) {
  //           next();
  //         }
  //       })
  //       .catch((err) => {
  //         switch (err.response.status) {
  //           case 400:
  //             console.log(400);
  //             next({ name: "error" });
  //             break;

  //           case 403:
  //             next({ name: "error" });
  //             break;

  //           default:
  //             next({ name: "error" });
  //             break;
  //         }
  //         console.log(err.response.status);
  //       });
  //   },
  // },
  {
    path: "/error",
    name: "error",
    component: ErrorView,
  },
  {
    path: "/",
    name: "tickets",
    component: TicketsView,
    beforeEnter: (to, from, next) => {
      console.log(to.query.token);
      axiosInstance
        .get(`/tokens/${to.query.token}`)
        .then((res) => {
          console.log("res status: ", res.status);
          console.log("res data: ", res.data);
          if (res.status === 200 || res.status === 201) {
            next();
          }
        })
        .catch((err) => {
          switch (err.response.status) {
            case 400:
              console.log(400);
              next({ name: "error" });
              break;

            case 403:
              next({ name: "error" });
              break;

            default:
              next({ name: "error" });
              break;
          }
          console.log(err.response.status);
        });
    },
  },
  {
    path: "/personal",
    name: "personal",
    component: PersonalView,
    beforeEnter: (to, from, next) => {
      console.log(to.query.token);
      axiosInstance
        .get(`/tokens/${to.query.token}`)
        .then((res) => {
          console.log("res status: ", res.status);
          console.log("res data: ", res.data);
          if (res.status === 200 || res.status === 201) {
            next();
          }
        })
        .catch((err) => {
          switch (err.response.status) {
            case 400:
              console.log(400);
              next({ name: "error" });
              break;

            case 403:
              next({ name: "error" });
              break;

            default:
              next({ name: "error" });
              break;
          }
          console.log(err.response.status);
        });
    },
  },
  {
    path: "/backoffice/home",
    name: "bohome",
    component: BOHomeView,
  },
  {
    path: "/backoffice/create",
    name: "bocreate",
    component: BOCreateView,
    beforeEnter: (to, from, next) => {
      console.log(to.query.token);
      axiosInstance
        .get(`/verify`)
        .then((res) => {
          console.log("res status: ", res.status);
          console.log("res data: ", res.data);
          if (res.status === 200 || res.status === 201) {
            next();
          }
        })
        .catch((err) => {
          switch (err.response.status) {
            case 400:
              console.log(400);
              next({ name: "bohome" });
              break;

            case 403:
              next({ name: "bohome" });
              break;

            default:
              next({ name: "bohome" });
              break;
          }
          console.log(err.response.status);
        });
    },
  },
  {
    path: "/backoffice/register",
    name: "boregister",
    component: BORegisterView,
  },
  {
    path: "/backoffice/link",
    name: "bolink",
    component: BOLinkView,
    beforeEnter: (to, from, next) => {
      console.log(to.query.token);
      axiosInstance
        .get(`/verify`)
        .then((res) => {
          console.log("res status: ", res.status);
          console.log("res data: ", res.data);
          if (res.status === 200 || res.status === 201) {
            next();
          }
        })
        .catch((err) => {
          switch (err.response.status) {
            case 400:
              console.log(400);
              next({ name: "bohome" });
              break;

            case 403:
              next({ name: "bohome" });
              break;

            default:
              next({ name: "bohome" });
              break;
          }
          console.log(err.response.status);
        });
    },
  },
  {
    path: "/testing", // This is the catch-all route for undefined paths
    name: "test",
    component: TestView,
  },
  {
    path: "/:catchAll(.*)", // This is the catch-all route for undefined paths
    name: "NotFound",
    component: NotFoundView,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    // Always scroll to top
    return { top: 0 };
  },
});

export default router;
