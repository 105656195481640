<template>
  <div>
    <h1>404 Page Not Found</h1>
  </div>
</template>

<script>

export default {
  name: "NotFoundView"
}
</script>

<style scoped>
div {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

div p {
  max-width: 500px;
  margin: 0 32px 0 32px;
}
</style>