<template>
  <div class="whole-container">
    
    <div class="nav-bar">
      <div class="nav-logo">
        <img src="../assets/img/icon/LOGO ARE TRUST(Verde)-V 1.png" />
      </div>
    </div>

    <div class="content-container">
      <div class="card-container">
        <h3 class="title">Bienvenido</h3>
        <div class="fields-container">
          <div class="field">
            <label class="label" for="username">Nombre de usuario</label>
            <input v-model="username" type="text" autocomplete="off" class="input" id="username" required>
          </div>
          <div class="field">
            <label class="label" for="password">Contraseña</label>
            <input v-model="password" type="password" autocomplete="off" class="input" id="password" required>
          </div>
        </div>
        <div class="btn-container">
          <button @click="onLogin" class="btn">Login</button>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import router from "@/router";
import { toast } from "vue3-toastify"

export default {
  name: "BORegisterView",
  data() {
    return {
      username: "",
      password: ""
    }
  },
  methods: {
    async onLogin() {
      if(this.username === "") {
        this.notify("Nombre de usuario no puede estar vacío")
        return;
      }

      if(this.password === "") {
        this.notify("Contraseña no puede estar vacía")
        return;
      }

      try {
        await this.$axios.post("/login", {
          username: this.username,
          password: this.password
        })
      } catch (error) {
        console.log(error.response.status)
        if (error.response.status == 500 || error.response.status == 501) {
          this.notify("Un error ocurrió, intenta de nuevo más tarde")
          return;
        }
        if (error.response.status != 200 && error.response.status != 201) {
          this.password = "";
          this.notify(error.response.data.err)
          return;
        }
      }

      this.username = ""
      this.password = ""
      router.push({"name": "bocreate"})
    },
    notify(message) {
      toast(message, {
        position: toast.POSITION.TOP_RIGHT,
        transition: toast.TRANSITIONS.FLIP,
        toastClassName: "my-toast"
      })
    }
  }
}

</script>

<style scoped>

.whole-container {
  margin: 0;
  display: flex;

  height: 100vh;
  flex-direction: column-reverse;
  background-color: #f8f8f8;
  color: #001A0D;

  font-family: "Roboto", sans-serif;
  overflow: hidden;
}

.nav-bar {
  display: flex;
  height: 80px;
  z-index: 10;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  flex-direction: row;
  margin: 0;
  justify-content: start;
  align-items: center;
  padding: 0 4.5rem 0 4.5rem;
  border-bottom: 1px solid #001A0D;
  background-color: #f8f8f8;
}

.content-container {
  width: 100%;
  margin: 0;
  height: calc(100% - 80px);
  display: flex;
  flex-direction: row;
  justify-content: center;
  overflow-y: scroll;

}

.card-container {
  display: flex;
  flex-direction: column;
  gap: auto;
  height: 390px;
  margin-top: 40px;
  padding: 24px 16px 40px 16px;
  box-shadow: 0px 0px 16px #001a0d63;
  width: 300px;
  justify-content: space-between;
}

.title {
  align-self: center;
  text-align: center;
  font-weight: 400;
  font-size: 24px;
}

.fields-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px
}

.field {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.label {
  font-size: 16px;
  color: #18271f;
}

.input {  
  padding: 8px;
  font-size: 16px;
  border: 2px solid #001A0D;
  transition: 100ms;
}

.input:focus {
  outline: none;
  border-right: 4px solid #001A0D;
}

.btn-container {
  width: 100%;
  text-align: center;
}

.btn-container .btn {
  font-size: 18px;
  padding: 12px 24px;
  border-radius: 999px;
  border: 2px solid #001A0D;
  background: none;
  transition: 120ms;
}

.btn-container .btn:hover {
  cursor: pointer;
  background-color: #001A0D;
  color: #f8f8f8;
  border: 2px solid #001A0D;
  padding: 12px 28px;
}

@media only screen and (max-width: 450px) {
  .content-container {
    padding: 0 16px;
  }

  .nav-bar {
    padding: 0 1rem 0 1rem;
  }

  .card-container {
    margin-right: 32px;
    box-shadow: 0px 0px 10px #001a0d63;

  }
}

</style>